import { create } from "zustand";

export const clientProfileStore = (set, get) => ({
    openClientProfile: false,
    clientId: "",
    openData: {},
    setOpenClientProfileView: (data) => {
        set(() => ({
            openClientProfile: data,
        }));
    },
    setClientId: (data) => {
        set(() => ({
            clientId: data,
        }));
    },
    setOpenData: (data) => {
        set(() => ({
            openData: data,
        }));
    },
});
export const useClientProfileStore = create(clientProfileStore);
