import React, { useEffect, useRef, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "./Header/Header.component";
import Sidebar from "./Sidebar/Sidebar.component";
import { isUserAuthenticated } from "helpers/auth";
import { KnockFeedProvider, NotificationFeedPopover, NotificationIconButton, useKnockFeed } from "@knocklabs/react-notification-feed";

import NotificationProvider from "providers/NotificationProvider";
import { useUserStore } from "store/Userdetails";
import { shallow } from "zustand/shallow";
import { useWebSocketStore } from "store/WebSocketStore";
import useIdleTimer from "hooks/useIdleTimer ";
import { addVisibilityChangeListener } from "hooks/visibility";
import { volumeDownIcon } from "@progress/kendo-svg-icons";
import moment from "moment";
const SOCKET_URL =  process.env.REACT_APP_WEB_SOCKET;

const Layout = () => {
    const socketRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    const [isOnline ,setIsOnline] = useState(false);
    const { userData } = useUserStore(
        (state) => ({
            userData: state.userData,
        }),
        shallow
    );
  
    const { setSwebSocketData, islogin, setSwebSocketDataDashBoard } = useWebSocketStore(
        (state) => ({
            setSwebSocketData: state.setSwebSocketData,
            islogin: state.islogin,
            setIslogin: state.setIslogin,
            setSwebSocketDataDashBoard:state.setSwebSocketDataDashBoard
        }),
        shallow
    );

    const connectWebSocket = (callid) => {
        const ws = new WebSocket(SOCKET_URL);
        ws.onopen = () => {
          const now = moment();
          console.log('WebSocket Connected', callid, now.format());
          setIsConnected(true);
          let id = userData?.data?.responseData?.userData?.user[0]?.id;
          ws.send(`{"action": "join", "userId" : ${id}, "activityStatus": "LOGIN"}`);
         
        };
  
        ws.onmessage = (event) => {
          const message = event.data;
          let data = JSON.parse(message);
          if (data?.status === "LOGIN" || data?.status === "LOGOUT") {
              setSwebSocketData(data);
          }

          if (data.eventType == "substatuschange" || data.eventType == "legassignmentchange") {
              setSwebSocketDataDashBoard(data);
          }
          
        };
  
        ws.onclose = () => {
          const now = moment();
          console.log('WebSocket disconnected', callid, now.format());
          setIsConnected(false);
          socketRef.current = '';
          if(isOnline){
            connectWebSocket(4);
          }
        };
  
        ws.onerror = (error) => {
          console.error('WebSocket error', error);
          ws.close(); // Close socket to trigger onclose
        };
  
        socketRef.current = ws;
      };

    useEffect(() => {
        const handleOnline = () => {
          const now = moment();
          console.log('Internet connected', now.format());
          setIsOnline(true)
          if (!isConnected) {
            connectWebSocket(2); // Attempt to reconnect
          }
        };
    
        const handleOffline = () => {
          setIsOnline(false)
          const now = moment();
          console.log('Internet disconnected', now.format());
          setIsConnected(false);
          if (socketRef.current) {
            console.log('11', 11)
            socketRef.current.close(); // Close socket on internet disconnection
          }
        };
    
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);
    
        return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
        };
      }, [isConnected]);

      useEffect(() => {
        // Create an interval ID to keep reference to the interval for clearing it later
        const intervalId = setInterval(() => {
            const token = localStorage.getItem("token");
            if (token == null) {
              console.log('22', 22)
                // Close socket on token absence
                socketRef.current && socketRef.current.close();
            }
        }, 500);
    
        // Cleanup function to clear the interval when the component unmounts
        // or before the effect runs again
        return () => {
            clearInterval(intervalId);
            console.log('33', 33)
            // Close socket on cleanup
            socketRef.current && socketRef.current.close();
        };
    }, []);
    
    useEffect(() => {
      if (!isConnected) {
        if (userData?.data?.responseData?.userData?.user[0]?.id) {
            connectWebSocket(1);
        }
    }
    }, [SOCKET_URL, userData?.data?.responseData?.userData?.user[0]?.id]);

    useEffect(() => {
      // Declare intervalId outside the listener callback scope to maintain the reference
      let intervalId = 1;
      const startInt = () => {
          // Set a new interval when the page becomes visible
          intervalId = setInterval(() => {
              let id = userData?.data?.responseData?.userData?.user[0]?.id;
              socketRef.current && socketRef.current.send(`{ action: "heartbeat", userId: ${id}, "activityStatus": "LOGIN"}`);
          }, 100000); // for 10 Min
      };
      if (intervalId === 1) {
          startInt();
      }
      const removeVisibilityChangeListener = addVisibilityChangeListener((isVisible) => {
          // Clear any existing intervals when visibility changes
          if (intervalId) {
              clearInterval(intervalId);
              intervalId = null;
          }
          if (isVisible) {
            if (socketRef.current === '') {
                if (userData?.data?.responseData?.userData?.user[0]?.id) {
                    connectWebSocket(3);
                }
            }
            startInt()
          }
      });
  
      // Clean up the event listener and clear the interval on component unmount
      return () => {
          removeVisibilityChangeListener();
          if (intervalId) {
              clearInterval(intervalId);
          }
      };
  }, []);
    const [rUrl, setRUrl] = useState(() => `/login?return_url=${encodeURIComponent(window.location.pathname)}`);

    return isUserAuthenticated() ? (
        <React.Fragment>
            {userData?.data?.responseData?.ad_access_token && (
                <KnockFeedProvider apiKey={process.env.REACT_APP_KNOCK_API_KEY} feedId={process.env.REACT_APP_KNOCK_FEED_ID} userId={userData?.data?.responseData?.userData?.userAzurAD[0].azure_ad}>
                    <NotificationProvider />
                </KnockFeedProvider>
            )}
            <Sidebar>
                <Header />
                {/* <div style={{paddingTop:70}}> ----Hide by sudhir---- */}
                <div>
                    <Outlet />
                </div>
            </Sidebar>
        </React.Fragment>
    ) : (
        <Navigate to={rUrl} replace={true} />
    );
};

export default Layout;
