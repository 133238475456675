import toastCheckImage from "assets/images/Vector.png";
import toastCrossImage from "assets/images/error-icon.png";
import moment from "moment";
import momentTZ from "moment-timezone";
import jetsIcon from "assets/images/jets-icon.svg";
import { renderToString } from "react-dom/server";
import parse from "html-react-parser";

export const redirectToExternalUrl = (url) => {
    window.open(url, "_blank", "noreferrer");
};
export const formateDate = (dateMilli) => {
    var d = (new Date(dateMilli) + "").split(" ");
    d[2] = d[2] + ",";

    return [d[1], d[2], d[3]].join(" ");
};

export const RenderCommaSeperatedValues = (data, keyName) => {
    if (Array.isArray(data)) return data?.map((role) => role[keyName])?.join(", ");
};

export const getNameInititals = (name) => {
    if (!name) return "";
    var matches = name.match(/\b(\w)/g);
    var acronym = matches.join("").split(" ");
    return acronym;
};

export const defaultSuccessMessage = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: 0,
    theme: "light",
    icon: ({ theme, type }) => <img src={toastCheckImage} alt="" />,
    className: "custom-color-success",
};
export const defaultErrorMessage = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    progress: 0,
    theme: "light",
    icon: ({ theme, type }) => <img src={toastCrossImage} alt="" />,
};

export const formateDateLegList = (date) => {
    return moment(date).format("MM-DD-YYYY | HH:mm");
};
export const formateDateBySlash = (date) => {
    return moment(date).format("MM/DD/YYYY | HH:mm");
};
export const formateDateWithoutSlash = (date) => {
    return moment(date).format("MM/DD/YYYY HH:mm");
};

export const formateDateFilters = (date) => {
    return moment(date).format("YYYY-MM-DD");
};
export const formateDateOnly = (date) => {
    if (!date) return "";
    return moment(date).format("MM/DD/YYYY");
};
export const formateDayMonthOnly = (date, userTimezone) => {
    // if (!date) return "";

    // var utcCutoff = moment.utc(date, "YYYYMMDD HH:mm:ss");
    // var displayCutoff = utcCutoff.clone().tz(userTimezone);
    // return displayCutoff.format("MM/DD");
    if (!date) return "";

    const displayDate = moment(date, "YYYYMMDD HH:mm:ss").format("MM/DD");
    return displayDate;
};
export const formateTime24Only = (date, userTimezone) => {
    // if (!date) return "";

    // var utcCutoff = moment.utc(date, "YYYYMMDD HH:mm:ss");
    // var displayCutoff = utcCutoff.clone().tz(userTimezone);
    // return displayCutoff.format("HH:mm");
    if (!date) return "";

    const displayTime = moment(date, "YYYYMMDD HH:mm:ss").format("HH:mm");
    return displayTime;
};

export const formateTime24OnlyWithoutDelimiter = (date, userTimezone) => {
    if (!date) return "";

    // var utcCutoff = moment.utc(date, "YYYYMMDD HH:mm:ss");
    // var displayCutoff = utcCutoff.clone().tz(userTimezone);
    // return displayCutoff.format("HHmm");
    const displayTime = moment(date, "YYYYMMDD HH:mm:ss").format("HHmm");
    return displayTime;
};
export const formateTime = (date, userTimezone) => {
    if (!date) return "";

    var utcCutoff = moment.utc(date, "YYYYMMDD HH:mm:ss");
    var displayCutoff = utcCutoff.clone().tz(userTimezone);
    return displayCutoff.format("YYYY-MM-DD HH:mm:ss");
};
export const formateTimeZone = (userTimezone) => {
    if (!userTimezone) return "";
    var date = new Date();
    var utcCutoff = moment.utc(date, "YYYYMMDD HH:mm:ss");
    var displayCutoff = utcCutoff.clone().tz(userTimezone);
    return displayCutoff.format("YYYY-MM-DD HH:mm:ss");
};

export const formatFilterDate = (date) => {
    if (!date) return;

    const yearFormat = date.match(/\b[0-9]{4}\b/) || "";
    const formattedDate = moment(date).format(`MMM DD, ${yearFormat}`);
    return formattedDate;
};
export const convertToHHMM = (value) => {
    const hours = Math.floor(value / 60); // Calculate the hours
    const minutes = value % 60; // Calculate the minutes

    // Format the hours and minutes with leading zeros if necessary
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
};

export const paxData = () => {
    const pax = [];

    for (let i = 1; i <= 200; i++) {
        pax.push({ id: i.toString(), name: i.toString() });
    }
    return pax;
};

export function isTodaysDate(date) {
    const today = new Date();
    const selectedDate = new Date(date);

    // Set the time of both dates to midnight (0 hours, 0 minutes, 0 seconds, 0 milliseconds)
    today.setHours(0, 0, 0, 0);
    selectedDate.setHours(0, 0, 0, 0);

    // Compare the dates
    return today.getTime() === selectedDate.getTime();
}

export const formatTimeForETE = (time) => {
    if (!time) return;
    const date = moment(time);
    const formattedDate = date.format("HH:mm");
    return formattedDate;
};
export function formatRelativeTime(dateString) {
    const date = new Date(dateString);
    const now = new Date();

    // Convert UTC time to local time
    const userOffset = now.getTimezoneOffset() * 60 * 1000;
    const localDate = new Date(date.getTime() - userOffset);
    const elapsed = now - localDate;
    const seconds = Math.floor(elapsed / 1000);
    const minutes = Math.floor(elapsed / (1000 * 60));
    const hours = Math.floor(elapsed / (1000 * 60 * 60));
    const days = Math.floor(elapsed / (1000 * 60 * 60 * 24));
    const months = Math.floor(elapsed / (1000 * 60 * 60 * 24 * 30));
    const years = Math.floor(elapsed / (1000 * 60 * 60 * 24 * 30 * 12));

    if (seconds < 60) {
        return "Reported Just Now";
    } else if (minutes < 60) {
        return `Reported ${minutes} Minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
        return `Reported ${hours} Hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 30) {
        return `Reported ${days} Day${days !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
        return `Reported ${months} Month${months !== 1 ? "s" : ""} ago`;
    } else {
        return `Reported ${years} Year${years !== 1 ? "s" : ""} ago`;
    }
}
export const formateTime12Only = (date, userTimezone) => {
    if (!date) return "";

    var utcCutoff = moment.utc(date, "YYYYMMDD HH:mm:ss");
    var displayCutoff = utcCutoff.clone().tz(userTimezone);
    return displayCutoff.format("HH:MM");
};
export const scenarioDateTimeFormat = (dateString, userTimezone) => {
    // if (!dateString) return;

    // const utcDate = moment.utc(dateString);
    // if (userTimezone) {
    //     // Convert the UTC date to the user's timezone and format it
    //     const formattedDate = utcDate.clone().tz(userTimezone).format("MMM DD, YYYY [at] h:mma");
    //     return formattedDate;
    // } else {
    //     // If no userTimezone provided, just format the UTC date
    //     const formattedUtcDate = utcDate.format("MMM DD, YYYY [at] h:mma [UTC]");
    //     return formattedUtcDate;
    // }
    if (!dateString) return "";

    const displayDate = moment(dateString, "YYYYMMDD HH:mm:ss").format("MMM DD, YYYY [at] HH:mm");
    return displayDate;
};

export const eteFieldSetTime = (targetTime) => {
    if (!targetTime) return;
    const currentDate = new Date(); // Get the current date

    const [hours, minutes] = targetTime?.split(":"); // Split the time into hours and minutes

    currentDate.setHours(hours); // Set the hours
    currentDate.setMinutes(minutes); // Set the minutes
    return currentDate; // Return the updated date with the added time
};
export function formatCurrency(number) {
    // Ensure the input is a valid number
    if (typeof number !== "number") {
        return "Invalid input";
    }

    // Format the number as a currency value
    const formattedCurrency = number.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });

    return formattedCurrency;
}

export function formatCurrencyWithoutDecimal(number) {
    // Ensure the input is a valid number
    if (typeof number !== "number") {
        return "Invalid input";
    }

    // Format the number as a currency value
    const formattedCurrency = number.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    return formattedCurrency;
}

export function seperateTimeAndMinutesForETE(time) {
    if (!time || typeof time !== "string") {
        return 0;
    }

    const [hours, minutes] = time.split(":").map(Number);

    if (isNaN(hours) || isNaN(minutes)) {
        return 0;
    }

    return hours * 60 + minutes;
}

export function convertToLocalTime(date) {
    var momentObj = moment.utc(date);

    // Get the user's local timezone using moment-timezone
    var userTimezone = moment.tz.guess();

    // Convert the date to the user's local timezone
    momentObj.tz(userTimezone);

    // Get the timezone initials (e.g., EST, IST) of the local timezone
    var timezoneInitials = moment.tz(userTimezone).format("z");

    const time = momentObj.format("HH:mm");
    // Format the converted time and append the timezone initials
    var formattedTime = momentObj.format("MM/DD/YYYY") + " " + time + " " + timezoneInitials;

    return formattedTime;
}
export function convertToLocalTimeSeperatedBySlash(date) {
    if (!date) return "";
    var momentObj = moment.utc(date);

    // Get the user's local timezone using moment-timezone
    var userTimezone = moment.tz.guess();

    // Convert the date to the user's local timezone
    momentObj.tz(userTimezone);

    // Get the timezone initials (e.g., EST, IST) of the local timezone
    var timezoneInitials = moment.tz(userTimezone).format("z");

    const time = momentObj.format("HH:mm");
    // Format the converted time and append the timezone initials
    var formattedTime = momentObj.format("MM-DD-YYYY") + " | " + time;

    return formattedTime;
}

export function convertTo24HourFormat(timeString) {
    // Parse the time string using Moment.js
    const time = moment(timeString, 'h:mma z');

    // Format the time in 24-hour format
    const formattedTime = time.format('HH:mm');

    return `${formattedTime} ${timeString.split(" ")[1]}`;
}

export const filterDataByHighestPriority = (dataArray, priorityString) => {
    const priorities = priorityString?.split(",").map(Number);
    const filteredData = dataArray?.filter((obj) => {
        const priority = obj.priority;
        const highestpriority = Math.min(...priorities);
        return priority === highestpriority;
    });
    return filteredData;
};
export const takeOutTimeFromDate = (date) => {};
export const truncateString = (string) => {
    if (string.length > 20) {
        return string?.slice(0, 20) + "...";
    }
    return string;
};
export const truncateMasterName = (dataArray) => {
    const truncatedArray = dataArray?.map((obj) => {
        if (obj?.name?.length > 20) {
            obj.name = obj?.name?.substring(0, 20) + "...";
        }
        return obj;
    });

    return truncatedArray;
};
export function formatDateRange(departDateStartTime, departDateEndTime, userTimezone) {
    // Convert input date and time values to UTC
    // const utcStartDate = moment.utc(departDateStartTime);
    // const utcEndDate = moment.utc(departDateEndTime);
    const utcStartDate = moment(departDateStartTime);
    const utcEndDate = moment(departDateEndTime);

    // Function to format date based on user's timezone
    // const formatUserDate = (date) => {
    //     return moment(date).tz(userTimezone).format("MMM DD, YYYY");
    // };
    const formatUserDate = (date) => {
        return moment(date).format("MMM DD, YYYY");
    };

    // Check if the months are the same
    if (utcStartDate?.month() === utcEndDate?.month()) {
        const startMonth = utcStartDate?.format("MMM");
        const startDay = utcStartDate?.date();
        const endDay = utcEndDate?.date();
        const year = utcStartDate?.year();
        if (startDay === endDay) {
            return `${startMonth} ${endDay}, ${year}`;
        }
        return `${startMonth} ${startDay} - ${endDay}, ${year}`;
    } else {
        const startFormatted = formatUserDate(utcStartDate);
        const endFormatted = formatUserDate(utcEndDate);

        return `${startFormatted} - ${endFormatted}`;
    }
}

export const deleteCookie = (cookieName, domain) => {
    // Set the expiration date to a past date
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain};`;
};

export const setCookie = (cname, cvalue, exdays, domain) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = `${cname}=${cvalue};${expires};path=${domain.path};domain=${domain.domain};`;
};

export const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
export const debounce = (func, delay = 100) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
};
export const convertMinutesInHourAndMinutes = (input) => {
    if (!input) return "Invalid input";
    const parts = input.split(" ");
    if (parts.length !== 2 || !parts[0].endsWith("h") || !parts[1].endsWith("m")) {
        return "Invalid input";
    }

    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);

    if (isNaN(hours) || isNaN(minutes)) {
        return "Invalid input";
    }

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
};
export const calculatePercentage = (tripsTaken, quoteRequested) => {
    if (quoteRequested === 0) {
        return 0;
    }
    const percentage = (tripsTaken / quoteRequested) * 100;
    return parseFloat(percentage.toFixed(2));
};
export const checkBrokerType = (inputString) => {
    if (!inputString) return "";
    const pairs = inputString?.split(", ");

    // Create an array of objects by iterating through the pairs
    const dataArray = pairs.map((pair) => {
        const [name, type] = pair.split("^^");
        return { brokerName: name, brokerType: type };
    });
    return dataArray;
};
export const checkBrokerTypeWithSpace = (inputString) => {
    if (!inputString) return "";
    const pairs = inputString?.split(", ");

    // Create an array of objects by iterating through the pairs
    const dataArray = pairs.map((pair) => {
        const [name, type] = pair.split(" ^^ ");
        return { brokerName: name, brokerType: type };
    });
    return dataArray;
};

export function formatBrokerTypes(data) {
    if (!data?.length) return [];
    const formattedData = data?.map((item) => {
        const cleanedBrokerName = item?.brokerName.replace(/\s*\^\^\s*/g, "");

        if (item?.brokerType === "Salaried") {
            const jsxElement = (
                <>
                    {cleanedBrokerName}{" "}
                    <span className="jets-icon">
                        <img src={jetsIcon} alt="Jets Icon" width="24" height="9" />
                    </span>
                </>
            );

            // Convert JSX element to string
            return renderToString(jsxElement);
        } else {
            return cleanedBrokerName;
        }
    });
    const formattedString = formattedData?.join(", ");

    return parse(formattedString);
}

export const rednderLegStatusIcon = (data) => {
    switch (data) {
        case "Scheduler Assigned":
            return <span className="scheduler-assigned elips-status-icon"></span>;
        case "In Queue":
            return <span className="in-queue elips-status-icon"></span>;
        case "Quote Review":
            return <span className="quote-review elips-status-icon"></span>;
        case "Client Review":
            return <span className="client-review elips-status-icon"></span>;
        case "Accepted":
            return <span className="accepted elips-status-icon"></span>;
        case "Draft":
            return <span className="draft elips-status-icon"></span>;
        case "In Transit":
            return <span className="in-transit elips-status-icon"></span>;
        case "Completed":
            return <span className="completed elips-status-icon"></span>;
        case "Cancelled":
            return <span className="cancelled elips-status-icon"></span>;
        case "Tail Assigned":
            return <span className="tail-assigned elips-status-icon"></span>;
        case "Operator Assigned":
            return <span className="tail-assigned elips-status-icon"></span>;

        default:
            break;
    }
};

export const rednderClientStatusIcon = (data) => {
    switch (data) {
        case "NewContact":
            return <span className="new-contact"></span>;
        case "Initiated":
            return <span className="initiated"></span>;
        case "PotentialClient":
            return <span className="potential-client"></span>;
        case "Client":
            return <span className="client"></span>;
        case "Reject":
            return <span className="rejected"></span>;

        default:
            break;
    }
};

export const rednderJetsatusIcon = (data) => {
    switch (data) {
        case "Draft":
            return <span class="draft"></span>;
        case "Scheduling":
            return <span class="scheduling"></span>;
        case "Pricing and Proposal":
            return <span class="scheduling"></span>;
        case "Quote Review":
            return <span class="quote-review"></span>;
        case "Client Review ":
            return <span class="client-review"></span>;
        case "Accepted":
            return <span class="accepted"></span>;
        case "Partially Assigned":
            return <span class="partially-assigned"></span>;
        case "Assignment Made":
            return <span class="assignment-made"></span>;
        case "Ongoing Trip":
            return <span class="ongoing-trip"></span>;
        case "Trip Review":
            return <span class="trip-review"></span>;
        case "Finance Review ":
            return <span class="finance-review"></span>;
        case "Finalized":
            return <span class="finalized"></span>;
        case "Cancelled":
            return <span class="cancelled"></span>;

        default:
            break;
    }
};

export const rednderTripStatusIcon = (data) => {
    switch (data) {
        case "Draft":
            return <span class="trip-status-elips-icon draft"></span>;
        case "Scheduling":
            return <span class="trip-status-elips-icon scheduling"></span>;
        case "Pricing and Proposal":
            return <span class="trip-status-elips-icon scheduling"></span>;
        case "Quote Review":
            return <span class="trip-status-elips-icon quote-review"></span>;
        case "Client Review":
            return <span class="trip-status-elips-icon client-review"></span>;
        case "Accepted":
            return <span class="trip-status-elips-icon accepted"></span>;
        case "Partially Assigned":
            return <span class="trip-status-elips-icon partially-assigned"></span>;
        case "Assignment Made":
            return <span class="trip-status-elips-icon assignment-made"></span>;
        case "Ongoing Trip":
            return <span class="trip-status-elips-icon ongoing-trip"></span>;
        case "Trip Review":
            return <span class="trip-status-elips-icon trip-review"></span>;
        case "Finance Review":
            return <span class="trip-status-elips-icon finance-review"></span>;
        case "Finalized":
            return <span class="trip-status-elips-icon finalized"></span>;
        case "Cancelled":
            return <span class="trip-status-elips-icon cancelled"></span>;

        default:
            break;
    }
};

export const getDailyMinimum = (rates) => {
    if (rates?.length) {
        const sortedRates = rates?.sort((a, b) => a.aircraftTypeId - b.aircraftTypeId);
        const formattedData = sortedRates.map((item) => `${item.aircraftType.replace(/ /g, "-")} : ${item.minDailyHours}`);
        return formattedData.join(" | ");
    }
    return " ";
};

export const customAPI = async (type, url, payload, config) => {
    const response = await fetch(url, {
        method: type,
        headers: { ...config?.headers },
    });
    let data = await response.json();
    if (data.ok) {
        return data;
    } else {
        return data;
    }
};

export const afterAcceptedStatusShow = (tripType, schedulerStatus) => {
    let JetStatus = [ "Pricing and Proposal", "Client Review"];
    let CharterStatus = [ "Scheduling", "Quote Review", "Client Review"];
    if (tripType == "1") {//Scheduling=>Quote Review
        return CharterStatus.includes(schedulerStatus.trim());
    } else if (tripType == "2") {
        return JetStatus.includes(schedulerStatus.trim());
    }
};
export const afterSendToBrokerStatusShow = (tripType, schedulerStatus) => {
    let JetStatus = ["Pricing and Proposal", "Client Review"];
    let CharterStatus = [ "Scheduling"];
    if (tripType == "1") {
        return CharterStatus.includes(schedulerStatus.trim());
    } else if (tripType == "2") {
        return true;
    }
};
export const isDraftStatusShow = (schedulerStatus) => {
    let CharterStatus = ["Draft"];
    return CharterStatus.includes(schedulerStatus.trim());
};
export function extractNumberFromString(val) {
    const integers = val.match(/-?\d+/g);
    if (integers) {
        const result = integers.map((number) => number.replace(/-/, "")).join("");
        return result;
    } else {
        return "";
    }
}

export function maskPhoneNumber(phoneNumber = "", countryCode = false) {
    function maskPhoneNumber(number) {
        const numberStr = number.toString();
        if (!Boolean(phoneNumber)) {
            return "";
        }
        // Format the number as a U.S. phone number: (XXX) XXX-XXXX
        const formattedNumber = `(${numberStr.substring(0, 3)}) ${numberStr.substring(3, 6)} ${number.length > 6 ? "-" : ""} ${numberStr.substring(6, numberStr.length)}`;
        return formattedNumber;
    }

    if (Boolean(countryCode) && Boolean(phoneNumber)) {
        return "+" + countryCode + " " + maskPhoneNumber(extractNumberFromString(phoneNumber));
    } else {
        return maskPhoneNumber(extractNumberFromString(phoneNumber));
    }
}

export const isNumber = (value) => {
    return typeof value === "number" && !isNaN(value);
};

export const tripStatusIdIsAfterAccepted = [4, 5, 7, 13, 15, 16, 21, 8, 9, 10, 11]
export const navigateMaverick = (url , tab = "_blank") => {
    window.open(`${process.env.REACT_APP_FE_MAVERICK}${url}`, tab);
};
