import React from "react";

const RenderUserStatus = ({ status }) => {
    return (
        <div className="status-col-sec">
            <span
                className="status-icon"
                style={{
                    background: status === true ? "#1C9240" : "#B4B6C3",
                }}
            ></span>
            <label>{status === true ? "Active" : "Inactive"}</label>
        </div>
    );
};

export default RenderUserStatus;
