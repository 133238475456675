import { getCookie, deleteCookie, setCookie } from "helpers";
import { Urls, buildAPIURL, buildURL, callApi } from "./api-urls";
import axios from "axios";
let domain = window.location.hostname == "localhost" ? "localhost" : ".jets.com";
const options = {
    path: "/",
    domain: domain,
    sameSite: true,
};
const TOKEN = "token";
const AD_TOKEN = "ad_token";
const LAST_API_TIMESTAMP = "last_api_timestamp";
const jets_access_token = process.env.REACT_APP_SSO_KEY + "_jets.access_token";
const jets_ad_access_token = process.env.REACT_APP_SSO_KEY + "_jets.ad_access_token";
const jets_refresh_token = process.env.REACT_APP_SSO_KEY + "_jets.refresh_token";
const isUserAuthenticated = () => {
    return localStorage.getItem(TOKEN);
};

const clearAll = () => {
    deleteCookie(jets_access_token, domain);
    deleteCookie(jets_ad_access_token, domain);
    deleteCookie(jets_refresh_token, domain);
    localStorage.clear();
};

const getToken = () => {
    return localStorage.getItem(TOKEN);
};

const getADToken = () => {
    return localStorage.getItem(AD_TOKEN);
};

const setToken = (token) => {
    localStorage.setItem(TOKEN, token);
};
const setADToken = (token) => {
    localStorage.setItem(AD_TOKEN, token);
};

// Idle Tab Management
const getLastApiTimestamp = () => {
    return localStorage.getItem(LAST_API_TIMESTAMP);
};
const setLastApiTimestamp = (timestamp) => {
    localStorage.setItem(LAST_API_TIMESTAMP, timestamp);
};
const getMVToken = () => {
    return getCookie(jets_access_token);
};
const getMVADToken = () => {
    return getCookie(jets_ad_access_token);
};
const getMVRefresh = () => {
    return getCookie(jets_refresh_token);
};

export const setMVToken = (access_token) => {
    setCookie(jets_access_token, access_token, 365, options);
};
export const setMVADToken = (ad_access_token) => {
    setCookie(jets_ad_access_token, ad_access_token, 365, options);
};
export const setMVRefresh = (refresh_token) => {
    setCookie(jets_refresh_token, refresh_token, 365, options);
};

export const fetchRefreshToken = async (callback) => {
    let url = buildURL(Urls.FetchRefreshToken, "", "", "MV");
    const result = await callApi.post(url, {
        code: getMVRefresh(),
    });
    if (result && result.responseCode === 200) {
        setMVToken(result.responseData);
        setToken(result.responseData);
        callback(null, false);
    } else {
        callback(null, true);
    }
};
export const setLocalStorageByKey = (key, token) => {
    localStorage.setItem(key, token);
};

export const logout = () => {
    clearAll();
    window.location.href = `http://${window.location.host}/login`;
};

export { isUserAuthenticated, clearAll, getToken, setToken, setADToken, getADToken, getLastApiTimestamp, setLastApiTimestamp, getMVToken, getMVADToken };
