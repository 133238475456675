import { lazy } from "react";

export const customeRoutes = {
    LoginScreen: lazy(() => import("pages/Login")),
    SystemManagementScreen: lazy(() => import("pages/SystemManagement")),
    UserPermissionsScreen: lazy(() => import("pages/UserPermissionsScreen")),
    Dashboard: lazy(() => import("pages/Dashboard")),
    LegListing: lazy(() => import("pages/LegListing")),
    EditRole: lazy(() => import("pages/SystemManagement/RoleManagementScreen/EditRole")),
    ScenarioBuilder: lazy(() => import("pages/ScenarioBuilder")),
    NotesPanel: lazy(() => import("pages/NotesPanel")),
    AviationScreen: lazy(() => import("pages/Aviation")),
    SendToBroker: lazy(() => import("pages/ScenarioBuilder/SendQuotesToBroker")),
};
