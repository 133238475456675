import React, { useCallback, useState } from "react";
import { TextField } from "components/common/TextField";
import { ErrorMessage, Field, Formik, Form, FieldArray } from "formik";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import * as Yup from "yup";
import { Upload } from "@progress/kendo-react-upload";

const fileStatuses = ["UploadFailed", "Initial", "Selected", "Uploading", "Uploaded", "RemoveFailed", "Removing"];

const CreateOperatorWindow = ({ openDialoag, toggle }) => {
    const ValidationSchema = Yup.object().shape({
        operatorName: Yup.string().max(255, "Cannot be more than 255 characters").required("Operator Name is Required"),
        email: Yup.string().max(255, "Cannot be more than 255 characters").required("Email is Required"),
        phoneType: Yup.string().required("Phone Type is Required"),
        emailType: Yup.string().required("Email Type is Required"),
        baseOfOperations: Yup.string().required("baseOfOperations Type is Required"),
        addressLine1: Yup.string().required("Address line 1 is Required"),
        addressLine2: Yup.string().required("Address line 2 is Required"),
        city: Yup.string().required("City is Required"),
        state: Yup.string().required("State is Required"),
        zip: Yup.string().required("Zip is Required"),
        country: Yup.string().required("Country is Required"),
        argus: Yup.string().required("Argus is Required"),
        wyvern: Yup.string().required("Wyvern is Required"),
        operatorPriority: Yup.string().required("Operator Priority is Required"),
        operatorNotes: Yup.string().required("Operator Notes is Required"),
        avinodeId: Yup.string().required("Avinode eId is Required"),
        emails: Yup.array().of(
            Yup.object().shape({
                email: Yup.string().email("Invalid email").required("Email is required"),
                emailType: Yup.string().required("Email Type is required"),
            })
        ),
        phones: Yup.array().of(
            Yup.object().shape({
                phone: Yup.string().required("Email is required"),
                phoneType: Yup.string().required("Email Type is required"),
            })
        ),
    });

    const [files, setFiles] = useState([]);
    const [events, setEvents] = useState([]);
    const [filePreviews, setFilePreviews] = useState({});

    const onAdd = (event) => {
        setFiles(event.newState);
        setEvents([...events, `File selected: ${event.affectedFiles[0].name}`]);
    };
    const onRemove = (event) => {
        let newFilePreviews = {
            ...filePreviews,
        };
        event.affectedFiles.forEach((file) => {
            delete newFilePreviews[file.uid];
        });
        setFiles(event.newState);
        setEvents([...events, `File removed: ${event.affectedFiles[0].name}`]);
        setFilePreviews(newFilePreviews);
    };
    const onProgress = (event) => {
        setFiles(event.newState);
        setEvents([...events, `On Progress: ${event.affectedFiles[0].progress} %`]);
    };
    const onStatusChange = useCallback(
        (event) => {
            const file = event.affectedFiles[0];
            setFiles(event.newState);
            setEvents([...events, `File '${file.name}' status changed to: ${fileStatuses[file.status]}`]);
        },
        [files, events]
    );

    return (
        <Dialog title={"ADD OPERATOR"} onClose={toggle} className="addContactDetailsModel configManagePopup">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    operatorName: "",
                    phoneType: "",
                    emails: [{ email: "", emailType: "" }],
                    phones: [{ phone: "", phoneType: "" }],
                    baseOfOperations: "",
                    addressLine1: "",
                    addressLine2: "",
                    city: "",
                    state: "",
                    argus: "",
                    country: "",
                    wyvern: "",
                    operatorPriority: "",
                    operatorNotes: "",
                    avinodeId: "",
                    aircraftUserNotUse: "",
                    zip: "",
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    console.log(values);
                }}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <Form>
                        <div>
                            <div className="phoenix-form">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-20">
                                            <TextField name="operatorName" type="text" label="Operator Name" maxLength={255} onChange={handleChange} onBlur={handleBlur} value={values.operatorName} />
                                        </div>
                                    </div>
                                </div>
                                <FieldArray
                                    name="emails"
                                    render={({ push, remove }) => (
                                        <>
                                            {values.emails.map((email, index) => (
                                                <div className="row mb-20">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <TextField
                                                                name={`emails[${index}].email`}
                                                                type="email"
                                                                label="Email"
                                                                maxLength={255}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={email.email}
                                                            />
                                                            {/* {errors.role && <Error>{errors.role}</Error>} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <Field className="form-select" as="select" name={`emails[${index}].emailType`} value={email.emailType} onChange={handleChange}>
                                                                <option value="">Email Type</option>
                                                                <option value={"1"}>{"test"}</option>
                                                            </Field>
                                                            {/* {!isBrokerNameValid && <Error>{"This is required field"}</Error>} */}
                                                            <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={`emails[${index}].emailType`} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group  mb-20">
                                                        <div className="addRoleBtn">
                                                            <a href="#" className="btn btn-link" onClick={() => push({ email: "", emailType: "" })}>
                                                                Add Another Email
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                />

                                <FieldArray
                                    name="phones"
                                    render={({ push, remove }) => (
                                        <>
                                            {values.phones.map((phone, index) => (
                                                <div className="row mb-20">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <TextField
                                                                name={`phones[${index}].phone`}
                                                                type="number"
                                                                label="phone"
                                                                maxLength={255}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={phone.phone}
                                                            />
                                                            {/* {errors.role && <Error>{errors.phone}</Error>} */}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <Field className="form-select" as="select" name={`phones[${index}].phoneType`} value={phone.phoneType} onChange={handleChange}>
                                                                <option value="">Phone Type</option>
                                                                <option value={"1"}>{"test"}</option>
                                                            </Field>
                                                            {/* {!isBrokerNameValid && <Error>{"This is required field"}</Error>} */}
                                                            <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={`phones[${index}].phoneType`} />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group  mb-20">
                                                        <div className="addRoleBtn">
                                                            <a href="#" className="btn btn-link" onClick={() => push({ phone: "", phoneType: "" })}>
                                                                Add Phone
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                />
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-20">
                                            <Field className="form-select" as="select" name="baseOfOperations">
                                                <option value="">Base of Operations</option>
                                                <option value={"1"}>{"test"}</option>
                                            </Field>
                                            {/* {!isBrokerNameValid && <Error>{"This is required field"}</Error>} */}
                                            <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"baseOfOperations"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-20">
                                            <TextField name="addressLine1" type="text" label="Address Line 1" maxLength={255} onChange={handleChange} onBlur={handleBlur} value={values.addressLine1} />
                                            {/* {errors.role && <Error>{errors.phone}</Error>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-20">
                                            <TextField name="addressLine2" type="text" label="Address Line 2" maxLength={255} onChange={handleChange} onBlur={handleBlur} value={values.addressLine2} />
                                            {/* {errors.role && <Error>{errors.phone}</Error>} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <Field className="form-select" as="select" name="city">
                                                <option value="">City</option>
                                                <option value={"1"}>{"test"}</option>
                                            </Field>
                                            {/* {!isBrokerNameValid && <Error>{"This is required field"}</Error>} */}
                                            <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"city"} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <Field className="form-select mb-20" as="select" name="state">
                                                <option value="">State</option>
                                                <option value={"1"}>{"test"}</option>
                                            </Field>
                                            {/* {!isBrokerNameValid && <Error>{"This is required field"}</Error>} */}
                                            <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"state"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-20">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <Field className="form-select" as="select" name="zip">
                                                <option value="">Zip</option>
                                                <option value={"1"}>{"test"}</option>
                                            </Field>
                                            {/* {!isBrokerNameValid && <Error>{"This is required field"}</Error>} */}
                                            <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"zip"} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <Field className="form-select" as="select" name="country">
                                                <option value="">Country</option>
                                                <option value={"1"}>{"test"}</option>
                                            </Field>
                                            {/* {!isBrokerNameValid && <Error>{"This is required field"}</Error>} */}
                                            <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"country"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-20">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <Field className="form-select" as="select" name="argus">
                                                <option value="">Argus</option>
                                                <option value={"1"}>{"test"}</option>
                                            </Field>
                                            {/* {!isBrokerNameValid && <Error>{"This is required field"}</Error>} */}
                                            <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"argus"} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <Field className="form-select" as="select" name="wyvern">
                                                <option value="">Wyvern</option>
                                                <option value={"1"}>{"test"}</option>
                                            </Field>
                                            {/* {!isBrokerNameValid && <Error>{"This is required field"}</Error>} */}
                                            <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"wyvern"} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <Field className="form-select" as="select" name="operatorPriority">
                                                <option value="">Op Priority</option>
                                                <option value={"1"}>{"test"}</option>
                                            </Field>
                                            {/* {!isBrokerNameValid && <Error>{"This is required field"}</Error>} */}
                                            <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"operatorPriority"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-20">
                                            <TextField
                                                name="operatorNotes"
                                                type="textarea"
                                                label="Operator Notes"
                                                maxLength={255}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.operatorNotes}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-20">
                                            <TextField
                                                name="avinodeId"
                                                type="text"
                                                label="Avinode Id map to Avinode Id"
                                                maxLength={255}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.avinodeId}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-20">
                                            <Checkbox defaultChecked={true} onChange={handleChange} name="aircraftUserNotUse" label="Mark Aircraft as Do not use" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group mb-20">
                                            <div className="profilePicUploadOuter">
                                                <Upload
                                                    className="uploadProfileImg"
                                                    batch={false}
                                                    multiple={true}
                                                    files={files}
                                                    onAdd={onAdd}
                                                    onRemove={onRemove}
                                                    onProgress={onProgress}
                                                    onStatusChange={onStatusChange}
                                                    withCredentials={false}
                                                    restrictions={{
                                                        allowedExtensions: [".jpg", ".png"],
                                                    }}
                                                    saveUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/save"}
                                                    removeUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/remove"}
                                                />
                                                <div className="uploadImageHints">
                                                    <h4>
                                                        Drag & Drop or <strong>Click to Browse File</strong>
                                                    </h4>
                                                    <p>Supports JPG, PNG up to 100mb</p>
                                                </div>
                                                <div className="uploadedProfileImg">
                                                    {Object.keys(filePreviews).map((fileKey, index) => (
                                                        <img className="rounded-circle" src={filePreviews[fileKey]} key={index} alt="" />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                            // className="modelFooterAction" commenting class because of design
                            >
                                <Button themeColor={"primary"} fillMode="flat" onClick={toggle}>
                                    Cancel
                                </Button>
                                <Button
                                    themeColor={"primary"}
                                    type="submit"
                                    //  onClick={toggle}
                                >
                                    Add Operator
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default CreateOperatorWindow;
