import React from "react";

const PageTitle = ({ title, subTitle, children }) => {
    return (
        <div className="top-title-sec mt-20 mb-15">
            <div className="row">
                <div className="col-6">
                    <label>{title}</label>
                    <h1>{subTitle}</h1>
                </div>
                {children}
            </div>
        </div>
    );
};

export default PageTitle;
