import axios from "axios";
import { toast } from "react-toastify";
import { throttle } from "lodash";
import { defaultErrorMessage } from "helpers";
import { clearAll, fetchRefreshToken, getMVADToken, getMVToken, getToken, logout, setLocalStorageByKey, setMVADToken, setMVToken } from "./auth";
import moment from "moment";
export const API_STATUS_CODE = {
    UNAUTHORIZE: 401,
    FORBIDDEN: 403,
    NOT_ACCEPTABLE: 406,
    SUCCESS: 200,
};

const FORBIDDEN_SUB_STATUS_CODE = {
    EXPIRED_JWT: "EXPIRED_JWT",
    MALFORMED_JWT: "MALFORMED_JWT",
    USER_NOT_FOUND: "USER_NOT_FOUND",
    UNAUTHORIZE_USER: "You are not authorized to login in to the application, kindly contact the system administrator.",
    UNAUTHORIZE_TOKEN: "failed to get access token",
};

const showUnauthorisedMessage = throttle(() => toast.warning("You are not authorized."), 4000);

axios.interceptors.response.use((response) => {
    if (response.data.responseCode === 401) {
        toast.error(response?.data?.responseMessage, defaultErrorMessage);
        clearAll();
        window.location.href = "/login";
    }

    return response;
});

export const Urls = {
    // Phoenix Api's
    Login: "v1/pheonix/login",
    MasterApiGetAllRoles: "master/all-roles",
    GetUserDetails: "v1/user",
    GetAllUsers: "users/all-users",
    SaveUserPermsisions: "users/add-user-role",
    CreateRole: "v1/createRole",
    EditRole: "v1/editRole",
    DeleteRole: "v1/deleteRole",
    GetRole: "users/get-role",
    GetUserDataWithFilters: "v1/userFilterRequest",
    GetLegListDataWithFilters: "legs/filter",
    GetAllTripTypes: "master/all-trip-types",
    GetAllScheduler: "master/all-scheduler-status",
    GetAllPriority: "master/all-priority",
    GetAllBrokers: "master/all-broker",
    GetAllSchedulers: "master/all-scheduler",
    GetAllAirports: "master/all-airports",
    AssignSchedulerToLeg: "legs/list-scheduler",
    AssignSchedulerPriority: "trips/assign-priority",
    GetCreateScenarioData: "scenario/scenario-builder-create",
    GetScenarioOperatorList: "scenario/list",
    DeleteScenarioOperatorFromOperatorList: "scenario-operator",
    CreateScenarioOperator: "scenario-operator",
    GetAllSchedulerStatus: "master/all-scheduler-status",
    GetAllSchedulerSubStatus: "master/all-scheduler-sub-status",
    AssignSchedulerSubStatus: "trips/assign-sub-status",
    ContractFileDelete: "scenario-operator/contract-file/",
    ScenarioOpertorActionStatus: "scenario/all-scenario-opertor-action-status",
    AssignOperatorStatus: "legs/assign-operator-status",
    SaveScenario: "scenario/save-scenario",
    ScenarioBuilderManually: "scenario/scenario-builder-manually",
    ScenarioList: "scenario/get-scenario/",
    SaveAsDraft: "scenario/save-as-draft",
    deleteScenrios: "scenario/delete-scenario/",
    GetAvinodeRedirectURL: "avinode/trip",
    GetAvinodeRedirectURLResponse: "sse",
    ScenarioBuilderSaveAsManually: "scenario/save-as-manualy",
    EmailNBAA: "config-setup/emails",
    AllPriceRange: "master/all-price-range",
    AllPriceRangeLineChart: "legs/price-range-trends",
    AllPriceRangeHistoryBarChart: "legs/price-range-history",
    addNotesToOperator: "legs/add-note-to-operator",
    legsFilterPagination: "legs/pagination",
    notesActivityLog: "trips/notes/activitylog/",
    noteList: "trips",
    getScenarioByTripId: "master/get-scenario-by-tripid/",
    queueDashlet: "dashboard/queue-dashlet",
    queueDashletPagination: "dashboard/queueDashlet-pagination",
    AllAssignedSchedulers: "master/all-assigned-schedulers",
    GetNotifications: "v1/notification/contactnotifications",
    Contactnotifications: "notifications",
    GetSearchBoxOptions: "master/search-box",
    GetScenarioBuilderLogs: "scenario/audit/scenario-builder/",
    ScenarioOperatorReadQuote: "scenario-operator/read-quote",
    SplitNotes: "legs/split-notes",
    CancelSystemTrips: "avinode/trip/cancelSystemTrips",
    updateAvinodeMessageStatus: (phoScenarioOperatorId) => `avinode/trip/updateAvinodeMessageStatus/${phoScenarioOperatorId}`,
    //Maverick API's
    GetClientProfile: "contacts/personalinfo",
    GetClientProfileBrokerInfo: "contacts/brokerinfo",
    GetTripDetails: (tripId) => `trip/detail/${tripId}/full`,
    GetTripDetailForClientPhoenix: `trips/trip-detail-for-client`,
    GetTripIds: `dashboard/filter-by-tripids`,
    LegsLinked: "legs/linked-legs",
    LegsById:"legs/legs-by-tripid",
    GetClientMarginAndPriorityDetails: (clientId) => `trips/${clientId}/legs-details`,
    SendToBroker: "scenario/send-to-broker",
    GetSpecialRequests: (tripId) => `trip/specialrequest/${tripId}`,
    InvokeSendToBrokerSync: (tripId) => `data-sync/send-to-boker-sync/${tripId}`,
    FetchRefreshToken: "refreshaccesstoken",
    OperatorsAddedCount: "avinode/operators-added-count",
    contactsLetcardOverview: (tripId) => `contacts/jetcard/overview/${tripId}`,
};

const axiosConfig = {
    headers: {
        "Content-Type": "application/json",
    },
};

export const buildURL = (url, queryParam, paramReplacements, source = "") => {
    let API_URL = process.env.REACT_APP_API_BASE_URL || "https://phenoixurl.com";
    if (source == "MV") {
        API_URL = process.env.REACT_APP_API_BASE_URL_MAVERICK;
    }
    if (queryParam) {
        url = addQueryParameters(url, queryParam);
    }

    if (paramReplacements) {
        url = replaceParams(url, paramReplacements);
    }

    return API_URL + url;
};
export const buildImgURL = (url) => {
    if (url) {
        const IMG_URL = process.env.REACT_APP_IMAGE_PATH;
        return IMG_URL + url;
    }
    return "";
};
const addQueryParameters = (url, params) => {
    const queryList = [];
    Object.keys(params).forEach((k) => {
        queryList.push(`${k}=${encodeURIComponent(params[k])}`);
    });
    const prefixCharacter = url.indexOf("?") === -1 ? "?" : "&";

    return `${url + prefixCharacter + queryList.join("&")}`;
};

const replaceParams = (url, params) => {
    Object.keys(params).forEach((k) => {
        url = url.replace(`:${k}`, encodeURIComponent(params[k]));
    });
    return url;
};

export const callApi = {
    get: async (url, config = {}) => {
        try {
            const response = await axios.get(url, {
                ...axiosConfig,
                ...config,
                headers: {
                    ...axiosConfig.headers,
                    ...(config.headers || {}),
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
    post: async (url, data, config = {}) => {
        try {
            const response = await axios.post(url, data, {
                ...axiosConfig,
                ...config,
                headers: {
                    ...(config.headers || axiosConfig.headers || {}),
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    ...(config?.headers || {}),
                },
            });

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
    put: async (url, data, config = {}) => {
        try {
            const response = await axios.put(url, data, {
                ...axiosConfig,
                ...config,
                headers: {
                    ...(config.headers || axiosConfig.headers || {}),
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
    patch: async (url, data, config = {}) => {
        try {
            const response = await axios.patch(url, data, {
                ...axiosConfig,
                ...config,
                headers: {
                    ...(config.headers || axiosConfig.headers || {}),
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
    delete: async (url, config = {}) => {
        try {
            const response = await axios.delete(url, {
                ...axiosConfig,
                ...config,
                headers: {
                    ...(config.headers || axiosConfig.headers || {}),
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
    otherMethod: async (url, method = "get", ...args) => {
        try {
            const response = await axios[method](url, ...args);

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
};

export const downloadFile = (url, fileName, method, postData) => {
    axios
        .request({
            url,
            method: method || "get",
            data: postData,
            responseType: "blob",
        })
        .then(({ data }) => {
            let a = document.createElement("a");
            let file = new Blob([data]);
            let fileURL = window.URL.createObjectURL(file);
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = `${fileName}`;
            a.click();
        });
};

export const formatElapsedTime = (date) => {
    const now = new Date();
    const elapsedMilliseconds = now - date;
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);
    const elapsedMonths = Math.floor(elapsedDays / 30);
    const elapsedYears = Math.floor(elapsedMonths / 12);

    if (elapsedSeconds < 60) {
        return "Just Now";
    } else if (elapsedMinutes < 60) {
        return `${elapsedMinutes} Minute${elapsedMinutes !== 1 ? "s" : ""} ago`;
    } else if (elapsedHours < 24) {
        return `${elapsedHours} Hour${elapsedHours !== 1 ? "s" : ""} ago`;
    } else if (elapsedDays < 30) {
        return `${elapsedDays} Day${elapsedDays !== 1 ? "s" : ""} ago`;
    } else if (elapsedMonths < 12) {
        return `${elapsedMonths} Month${elapsedMonths !== 1 ? "s" : ""} ago`;
    } else {
        return `${elapsedYears} Year${elapsedYears !== 1 ? "s" : ""} ago`;
    }
};

let isTokenRefreshing = false; // For avoiding multiple refresh token call
let requestCaching = [];
axios.interceptors.response.use(
    (response) => {
        setLocalStorageByKey("last_api_timestamp", moment().toString());
        return Promise.resolve(response);
    },
    (error) => {
        const { config, response } = error;
        const originalRequest = config;
        if (originalRequest.url.includes("authenticate-sso")) {
            logout();
            return Promise.resolve("");
        }
        if (response?.status === API_STATUS_CODE.FORBIDDEN) {
            return Promise.reject(error);
        } else if (response?.status === API_STATUS_CODE.UNAUTHORIZE) {
            if (response?.data.responseMessage == FORBIDDEN_SUB_STATUS_CODE.EXPIRED_JWT) {
                if (!isTokenRefreshing) {
                    isTokenRefreshing = true;
                    fetchRefreshToken((_, isError) => {
                        if (isError) {
                            logout();
                        } else {
                            setTimeout(() => {
                                recallCachedAPIs();
                                requestCaching = [];
                                isTokenRefreshing = false;
                            }, 1000); // To handle parallel API Calls
                        }
                    });
                }
                // Cache all API call and resolve only after token refreshed!
                return new Promise((resolve, reject) => {
                    subscribeToken((token) => {
                        if (originalRequest.baseURL == process.env.REACT_APP_API_BASE_URL_MAVERICK) {
                            const token = getMVToken();
                            originalRequest.headers.Authorization = "Bearer " + token;
                        } else {
                            const token = getToken();
                            originalRequest.headers.Authorization = "Bearer " + token;
                        }

                        resolve(axios(originalRequest));
                    });
                });
            } else if (response.data.responseMessage === FORBIDDEN_SUB_STATUS_CODE.UNAUTHORIZE_USER || response.data.responseMessage === FORBIDDEN_SUB_STATUS_CODE.UNAUTHORIZE_TOKEN) {
                return Promise.reject(error);
            } else {
                logout();
            }
        } else {
            return Promise.reject(error);
        }
    }
);

// Cached all Http calls which one failed
const subscribeToken = (cb = (token) => {}) => {
    requestCaching.push(cb);
};

// Recall once the access token refreshed
const recallCachedAPIs = () => {
    const token = getMVToken();
    if (token) {
        requestCaching.forEach((cb) => cb(token));
    }
};
