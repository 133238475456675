import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const legListFilterTableStore = (set, get) => ({
    tripType: [],
    tripId: "",
    clientName: "",
    departureStartDate: new Date() || "",
    departureEndDate: "",
    origin: [],
    destination: [],
    broker: [],
    scheduler: [],
    pax: [],
    eteStartTime: "",
    eteEndTime: "",
    departQuickDate: "",
    schedulerSubStatus: "",
    schedulerMultiStatus: [
        {
            id: 4,
            status: "Accepted",
            active: true,
            deleted: false,
        },
        {
            id: 3.1,
            status: "Jet Card Review",
            code: "JETCARDREVIEW",
            active: true,
            deleted: false,
        },
        {
            id: 1,
            status: "In Queue",
            active: true,
            deleted: false,
        },
        {
            id: 8,
            status: "In Transit",
            active: true,
            deleted: false,
        },
        {
            id: 5,
            status: "Scheduler Assigned",
            active: true,
            deleted: false,
        },
        {
            id: 7,
            status: "Operator Assigned",
            active: true,
            deleted: false,
        },
    ],
    priority: [],
    sort: [
        {
            field: "departDateTime",
            dir: "asc",
        },
    ],
    filtersApplied: false,
    lastAppliedFilters: {},
    refreshLegList: 0,
    searchKey: "",
    isEnterSearch: false,

    onClickAppliedFilter: (data) => {
        set(() => ({
            tripType: data?.tripType || [],
            tripId: data?.tripId || "",
            clientName: data?.clientName || "",
            departureStartDate: data?.departureStartDate || "",
            schedulerSubStatus: data?.schedulerSubStatus || "",
            departureEndDate: data?.departureEndDate || "",
            origin: data?.origin || [],
            destination: data?.destination || [],
            broker: data?.broker || [],
            scheduler: data?.scheduler || [],
            schedulerMultiStatus: data?.schedulerMultiStatus || [],
            pax: data?.pax || [],
            eteStartTime: data?.eteStartTime || "",
            eteEndTime: data?.eteEndTime || "",
            departQuickDate: data?.departQuickDate || "",
            priority: data?.priority || [],
            refreshLegList: 0,
            searchKey: "",
            isEnterSearch: false,
        }));
    },

    sortLegListGrid: (e) => {
        set(() => ({
            sort: e.sort,
        }));
    },
    setIsResetEnterSearch: (e) => {
        set(() => ({
            isEnterSearch: false,
        }));
    },
    resetLegListGridFilter: (reset) => {
        set(() => ({
            tripType: [],
            tripId: "",
            clientName: "",
            departureStartDate: reset ? "" : new Date() || "",
            departureEndDate: "",
            schedulerSubStatus: "",
            origin: [],
            destination: [],
            broker: [],
            scheduler: [],
            // schedulerMultiStatus: [],
            pax: [],
            eteStartTime: "",
            eteEndTime: "",
            departQuickDate: "",
            schedulerMultiStatus: [
                {
                    id: 4,
                    status: "Accepted",
                    active: true,
                    deleted: false,
                },
                {
                    id: 3.1,
                    status: "Jet Card Review",
                    code: "JETCARDREVIEW",
                    active: true,
                    deleted: false,
                },
                {
                    id: 1,
                    status: "In Queue",
                    active: true,
                    deleted: false,
                },
                {
                    id: 8,
                    status: "In Transit",
                    active: true,
                    deleted: false,
                },
                {
                    id: 5,
                    status: "Scheduler Assigned",
                    active: true,
                    deleted: false,
                },
                {
                    id: 7,
                    status: "Operator Assigned",
                    active: true,
                    deleted: false,
                },
            ],
            priority: [],
            sort: [
                {
                    field: "departDateTime",
                    dir: "asc",
                },
            ],
            filtersApplied: false,
            refreshLegList: 0,
            searchKey: "",
            isEnterSearch: false,
        }));
    },
    applyFilterForTripId: (data, refresh, searchValue) => {
        set(() => ({
            tripId: data,
            refreshLegList: refresh,
            searchKey: searchValue,
            isEnterSearch: true,
        }));
    },
    setIsLegListFiltersApplied: () => {
        set(() => ({
            filtersApplied: true,
        }));
    },

    lastAppliedFilter: (prevFilters) => {
        set((state) => ({ lastAppliedFilters: prevFilters }));
    },

    removeQuickFilter: (filterName) => {
        set(() => ({
            [filterName]: "",
        }));
    },
    applyQuickFilerForDepartureDate: (check) => {
        set(() => ({
            departQuickDate: new Date(),
        }));
    },
    removeQuickFilerForDepartureDate: () => {
        set(() => ({
            departQuickDate: "",
        }));
    },
    applyQuickFilerForStatus: (data) => {
        set(() => ({
            schedulerMultiStatus: [...data],
        }));
    },
    removeQuickFilerForStatus: (data) => {
        set(() => ({
            schedulerMultiStatus: data?.filter((f) => f.status !== "In Queue"),
        }));
    },
    removeQuickFilerForAllStatus: (data) => {
        set(() => ({
            schedulerMultiStatus: [],
        }));
    },

    removeDepartureDates: () => {
        set(() => ({
            departureStartDate: "",
            departureEndDate: "",
        }));
    },
    removeSearchKey: () => {
        set(() => ({
            searchKey: "",
        }));
    },
    removeQuickFilterArray: (name, id) => {
        switch (name) {
            case "broker":
                set((state) => ({
                    broker: state.broker?.filter((filteredBroker) => filteredBroker.broker_id !== id),
                }));
                break;
            case "schedulerMultiStatus":
                set((state) => ({
                    schedulerMultiStatus: state.schedulerMultiStatus?.filter((filteredBroker) => filteredBroker.id !== id),
                }));
                break;

            default:
                set((state) => ({
                    scheduler: state.scheduler?.filter((filteredScheduler) => filteredScheduler.id !== id),
                }));
                break;
        }
    },
});
export const useLegListFilterTableStore = create(
    persist(legListFilterTableStore, {
        name: "legListGrid",
    })
);
