import React, { useEffect } from "react";
import "assets/css/Header.scss";
import { Button } from "@progress/kendo-react-buttons";
import JetLogo from "assets/images/jet-logo.svg";
import PhoenixLogo from "assets/images/phoenix-logo.png";
import SearchBlue from "assets/images/search-blue.svg";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "store/Userdetails";
import { clearAll } from "helpers/auth";
import { shallow } from "zustand/shallow";
import { debounce, getNameInititals } from "helpers";
import Notifications from "navigation/Notification";
import { useState } from "react";
import { Urls, buildURL, callApi } from "helpers/api-urls";
import { useLegListFilterTableStore } from "store/LegListStore";
import useAxiosPost from "hooks/useAxiosPost";
const Header = () => {
    const [userImage, setUserImage] = useState();
    const [loading, setLoading] = useState(false);
    const popupRef = React.useRef(null);
    const [state, setState] = React.useState([]);
    const [openedDropDown , setOpenedDropDown] = React.useState(false)
    const [value, setValue] = useState("");
    const [opened, setOpened] = useState(false);
    const [clicked, setClicked] = useState(false);
    const navigate = useNavigate();
    const openSidebar = () => {
        document.getElementById("my-sidebar").style.width = "100%";
        document.getElementById("main").style.display = "none";
        document.getElementById("my-sidebar").style.marginLeft = "0";
    };
    const { userData } = useUserStore(
        (state) => ({
            userData: state.userData,
        }),
        shallow
    );
    const { applyFilterForTripId, refreshLegList, resetLegListGridFilter, removeQuickFilerForStatus, schedulerMultiStatus, removeQuickFilerForAllStatus } = useLegListFilterTableStore(
        (state) => ({
            applyFilterForTripId: state.applyFilterForTripId,
            refreshLegList: state.refreshLegList,
            resetLegListGridFilter: state.resetLegListGridFilter,
            removeQuickFilerForStatus: state.removeQuickFilerForStatus,
            schedulerMultiStatus: state.schedulerMultiStatus,
            removeQuickFilerForAllStatus: state.removeQuickFilerForAllStatus,
        }),
        shallow
    );

    useEffect(() => {
        if (userData?.data?.responseData?.userData?.user[0].firstName) {
            return;
        } else {
            clearAll();
            navigate("/login");
        }
    }, [navigate, userData?.data?.responseData?.name]);

    const jumpAfterSearch = (id) => {
        window.open(`${process.env.REACT_APP_ORIGIN_URL}/scenario-builder/${id}`, '_blank')
        setOpened(false);
    };
    const [searchValue, setSearchValue] = React.useState("");
    const { loadingPostData: tripIdDataLoading, sendRequest: sendRequestToGetTripIds, data: getTripIdsResponse } = useAxiosPost();
    const debounceSearch = React.useCallback(debounce(async (event) => {
        setOpenedDropDown(false)
        setLoading(true);
        const response = await callApi.post(buildURL(Urls.GetSearchBoxOptions), { searchKey: event.target.value });
        setState(response?.responseData);
        setOpenedDropDown(event.target.value ? true : false );
        setLoading(false);
        const eventType = event.nativeEvent.type;
        const nativeEvent = event.nativeEvent;
        const valueSelected = eventType === "click" || (eventType === "keydown" && nativeEvent.keyCode === 13);
        if (valueSelected && response?.responseData?.some((r) => r?.name === event.target.value)) {
            setLoading(false);
            const {id} = state.find((r) => r?.name === event.target.value)
            jumpAfterSearch(id);
        }
        setLoading(false);
    }, 1000), [])

    const onChange = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        setOpenedDropDown(false)
        setLoading(false);
        value.length < 3 ? setOpened(false) : setOpened(true);
        if (value.length > 2) {
            debounceSearch(event)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.closest(".k-animation-container")) {
                setOpened(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [opened]);
    useEffect(() => {
        if (clicked && state?.some((r) => r?.name === value)) {
            const {id} = state.find((r) => r?.name === value)
            jumpAfterSearch(id);
            setOpened(false)
        }
        return () => setClicked(false);
    }, [value, clicked]);

    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <span
                ref={popupRef}
                style={{
                    color: "#00F",
                    width: '100%'
                }}
                onClick={(event) => {
                    setValue(itemProps.dataItem.name);
                    setClicked(true);
                }}
            >
                {li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    };
    return (
        <header className="main-header fixed-top search-categories">
            <div className="container-fluid">
                <div className="header-wrapper">
                    <div
                        className="logo-section"
                        onClick={() => {
                            navigate("/");
                        }}
                    >
                        <a className="jet-logo">
                            <img src={JetLogo} alt="Jets" />
                        </a>
                        <a className="phoenix-logo">
                            <img src={PhoenixLogo} alt="Phoenix" />
                        </a>
                    </div>
                    <div className="middle-section">
                        <div className="search-icon d-none d-md-flex">
                            <img src={SearchBlue} alt="Search" width="18" height="18" />
                        </div>
                        <div
                            onKeyUp={(e) => {
                                const eventType = e.nativeEvent.type;
                                const nativeEvent = e.nativeEvent;
                                const valueSelected = nativeEvent.keyCode === 13;
                                if (valueSelected && state?.some((r) => r?.name === searchValue)) {
                                    const { id } = state.find((r) => r?.name === searchValue);
                                    jumpAfterSearch(id);
                                }
                            }}
                            className="search-form  d-none d-md-block"
                            // style={{ display: isMobile && !isOpened ? "none" : "flex" }}
                        >
                            <AutoComplete
                                popupSettings={{ popupClass: "auto-complete-search" }}
                                data={state}
                                onChange={onChange}
                                className="custom-input"
                                loading={loading}
                                placeholder="Search Trip ID, Airport, Operator, Client and Tail#"
                                dataItemKey="id"
                                textField="name"
                                itemRender={itemRender}
                                opened = {openedDropDown}
                                onBlur={() => setOpenedDropDown(false)}
                            />
                            <Button fillMode="link" className="header-close-btn d-none" type="button"></Button>
                            <div className="seach-overlay"></div>
                            <Button fillMode="link" className="search-text d-block d-md-none" type="button">
                                Search
                            </Button>
                        </div>
                        <Button
                            fillMode="link"
                            className="header-search-btn d-block d-md-none"
                            type="submit"
                            // onClick={toggle}
                        ></Button>
                        <Notifications />
                        {/* <div class="notification-sec">
                            <div class="dropdown">
                                <button type="submit" class="k-button k-button-md k-button-link k-button-link-base k-rounded-md notification-btn"></button>
                            </div>
                        </div> */}
                        <Button fillMode="link" className="menu-icon-btn" type="submit" onClick={openSidebar}></Button>
                    </div>
                    <div className="user-sec">
                        <div className="dropdown">
                            <Button fillMode="outline">
                                <div className="user-pic">{userImage ? <img src={userImage} /> : <span>{getNameInititals(userData?.data?.responseData?.userData?.user?.[0]?.firstName)}</span>}</div>
                                <div className="user-name-sec">
                                    <span>{"Sales"}</span>
                                    <strong>{getNameInititals(userData?.data?.responseData?.userData?.user?.[0]?.firstName)}.</strong>
                                </div>
                            </Button>
                            <ul className="dropdown-menu">
                                <li>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                                    <a
                                        className="dropdown-item"
                                        href={void 0}
                                        onClick={() => {
                                            clearAll();
                                            const rUrl = `/login?return_url=${encodeURIComponent(window.location.pathname)}`;
                                            navigate(rUrl);
                                        }}
                                    >
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
