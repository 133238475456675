import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const notificationStore = (set, get) => ({
    primaryNotifications: [],
    notifications: [],
    totalUnread: 0,
    page: 0,
    size: 20,
    totalLength: 0,

    updateNotification: (data) => {
        set((state) => ({
            primaryNotifications: data.primaryNotifications || state.primaryNotifications,
            notifications: data.notifications || state.notifications,
            totalUnread: data.totalUnread || data.totalUnread == 0 ? data.totalUnread : state.totalUnread,
            page: data.page || state.page,
            size: data.size || state.size,
            totalLength: data.totalLength || data.totalLength == 0 ? data.totalLength : state.totalLength,
        }));
    },
});
export const useNotificationStore = create(
    persist(notificationStore, {
        name: "notification",
    })
);
