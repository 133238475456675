import React, { useState, useEffect } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Badge } from "@progress/kendo-react-indicators";
import { Switch } from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router-dom";
import { shallow } from "zustand/shallow";
import moment from "moment";
import { useNotificationStore } from "store/AppNotificationStore";
import { Urls, callApi } from "helpers/api-urls";
import useAxiosPost from "hooks/useAxiosPost";
import { useUserStore } from "store/Userdetails";
import { useKnockFeed } from "@knocklabs/react-notification-feed";
import { useTripProfileStore } from "store/TripProfileStore";

const isDateOfThisWeekAndLastWeek = (inputDate, format) => {
    // get the start and end of the current week
    const startOfWeek = moment().startOf("week");
    const endOfWeek = moment().endOf("week");

    // get the start and end of the previous week
    const startOfLastWeek = moment().subtract(1, "week").startOf("week");
    const endOfLastWeek = moment().subtract(1, "week").endOf("week");

    // convert the input date to a moment object
    const date = moment(inputDate, format);

    if (date.isBetween(startOfWeek, endOfWeek)) {
        return true;
    } else if (date.isBetween(startOfLastWeek, endOfLastWeek)) {
        return false;
    } else {
    }
};

const Notifications = () => {
    const { userData } = useUserStore(
        (state) => ({
            userData: state.userData,
        }),
        shallow
    );
    const { updateNotification, totalUnread, totalLength, primaryNotifications, notifications } = useNotificationStore(
        (state) => ({
            updateNotification: state.updateNotification,
            notifications: state.notifications,
            totalUnread: state.totalUnread,
            primaryNotifications: state.primaryNotifications,
        }),
        shallow
    );

    const navigate = useNavigate();
    const [onlyUnread, setOnlyUnread] = useState(false);

    const MarkAllAsReadHandler = async (ids = []) => {
        callApi
            .put(`${Urls.Contactnotifications}/visit`, {
                ids: ids,
                clearAll: ids.length ? false : true,
            })
            .then(({ response, responseCode }) => {
                if (responseCode === 200) {
                    let totalU = 0;
                    if (ids.length) {
                        totalU = totalUnread - 1;
                    }
                    updateNotification({
                        totalUnread: totalU,
                        notifications: notifications.map((not) => {
                            if (ids.length) {
                                let idss = ids.indexOf(not.id) != -1;
                                if (idss) {
                                    not.isVisited = true;
                                }
                            } else {
                                not.isVisited = true;
                            }
                            return not;
                        }),
                        primaryNotifications: notifications.map((not) => {
                            if (ids.length) {
                                let idss = ids.indexOf(not.id) != -1;
                                if (idss) {
                                    not.isVisited = true;
                                }
                            } else {
                                not.isVisited = true;
                            }
                            return not;
                        }),
                    });
                }
            });
    };

    const GetNotificationTime = ({ time }) => {
        const format = "yyyy-MM-DD[T]HH:mm:ss.SSSZ";
        const notificationDateTime = moment(time).tz("UTC").format(format); //moment(time).tz(time, format, "UTC"); // Parse time as UTC
        const localNotificationDateTime = moment(notificationDateTime).clone().local(); // Convert to local time
        const localNotificationTime = localNotificationDateTime.format("HH:mm A");
        const lastWeekSunday = moment().subtract(1, "week").startOf("week").diff(moment(), "days");
        if (localNotificationDateTime.isSame(moment(), "day")) {
            return <span>Today at {localNotificationTime}</span>;
        } else if (localNotificationDateTime.isSame(moment().subtract(1, "day"), "day")) {
            return <span>Yesterday at {localNotificationTime}</span>;
        } else if (localNotificationDateTime.isAfter(moment().subtract(-lastWeekSunday, "days"))) {
            if (isDateOfThisWeekAndLastWeek(time, format)) {
                return (
                    <span>
                        This {localNotificationDateTime.format("dddd")} at {localNotificationTime}
                    </span>
                );
            } else {
                return (
                    <span>
                        Last {localNotificationDateTime.format("dddd")} at {localNotificationTime}
                    </span>
                );
            }
        } else {
            return <span>{localNotificationDateTime.format("dddd, MMMM DD, yyyy")}</span>;
        }
    };

    const numberOfUnreadNotification = () => {
        return totalUnread;
    };

    const filterNotifications = (unread) => {
        return notifications.filter((not) => {
            if (unread) {
                return !not.isVisited;
            } else {
                return true;
            }
        });
    };

    const { loadingPostData: notificationsLoading, sendRequest: fetchNotifications, data: notificationsList } = useAxiosPost();
    useEffect(() => {
        if (notificationsList) {
            updateNotification({
                primaryNotifications: notificationsList.responseData.page.content,
                notifications: notificationsList.responseData.page.content,
                totalUnread: notificationsList?.responseData.unreadNotificationCount,
            });
        }
    }, [notificationsList]);

    const getNotifications = (read = false) => {
        let url = "";
        if (read) {
            url = `&isVisited=${!read}`;
        }
        fetchNotifications("get", `${Urls.Contactnotifications}/${userData.data.responseData.userData.user[0].id}?size=20&page=0${url}`, "");
    };
    const { setOpenTripProfileView, setTripId, setDataSourceIdForTrip  } = useTripProfileStore(
        (state) => ({
            setOpenTripProfileView: state.setOpenTripProfileView,
            setTripId: state.setTripId,
            setDataSourceIdForTrip: state.setDataSourceIdForTrip,
        }),
        shallow
    )
    const NotificationList = () => {
        return (
            <ul>
                {notifications &&
                    filterNotifications(onlyUnread).map((not, index) => {
                        const nameArr = (not?.createdByName ?? "").split(" ");
                        const firstName = nameArr[0];
                        const lastName = nameArr.length > 1 && nameArr[1];
                        return (
                            <li
                                key={`notification-li-${not.id}-${index}`}
                                onClick={() => {
                                    MarkAllAsReadHandler([not.id]);
                                    setOpenTripProfileView(true);
                                    setDataSourceIdForTrip(not?.entityId);
                                    setTripId(not?.tripId);
                                }}
                            >
                                <span className="k-button-text">
                                    <div className="user-pic-sec">
                                        {!Boolean(not?.isVisited) && <Badge />}
                                        <div className="user-pic">
                                            <span className="inititials-img">
                                                {firstName && firstName.charAt(0).toUpperCase()}
                                                {lastName && lastName.charAt(0).toUpperCase()}
                                            </span>
                                        </div>
                                    </div>
                                </span>
                                <div className="user-data-sec">
                                    <h6>{not?.message}</h6>
                                    <span className="date-time">
                                        <GetNotificationTime time={not.createdDate} />
                                    </span>
                                </div>
                            </li>
                        );
                    })}
            </ul>
        );
    };

    return (
        <div className="notification-sec">
            <div className="dropdown">
                <Button fillMode="link" className="notification-btn k-button k-button-md k-button-link k-button-link-base k-rounded-md notification-btn" type="submit">
                    {numberOfUnreadNotification() > 0 && <Badge themeColor="error">{numberOfUnreadNotification() <= 9 ? numberOfUnreadNotification() : "9+"}</Badge>}
                </Button>
                <div className="dropdown-menu">
                    <div className="notification-title-sec">
                        <h5>Notifications</h5>
                        <div className="read-unread-switch">
                            <Switch
                                checked={onlyUnread}
                                onChange={() => {
                                    setOnlyUnread(!onlyUnread);
                                    getNotifications(!onlyUnread);
                                }}
                            />
                            <label>Unread Only</label>
                        </div>
                        <Button
                            fillMode="link"
                            className="btn-link"
                            type="button"
                            disabled={numberOfUnreadNotification() == 0}
                            onClick={() => {
                                MarkAllAsReadHandler();
                            }}
                        >
                            Mark all as read
                        </Button>
                    </div>
                    <div className="notification-scroll-sec custom-scrolbar">
                        <NotificationList />
                    </div>
                    <div className="all-notification">
                        <Button fillMode="link" disabled={true} className="btn-link" type="button">
                            View All Notifications
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notifications;
