import { create } from "zustand";

export const SubStatusStore = (set, get) => ({
    subStatusData: null,
    setSubStatusData: (data) => {
        set(() => ({
            subStatusData: data,
        }));
    },
});
export const useSubStatusStore = create(SubStatusStore);
