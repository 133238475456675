import React, { useEffect } from "react";
import { useHref, useNavigate } from "react-router-dom";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import "assets/css/Sidebar.scss";
import SidebarLogo from "assets/images/sidebar-logo.svg";
import Permissions from "components/common/Permissions";
import { clearAll } from "helpers/auth";

const Sidebar = ({ children }) => {
    const navigate = useNavigate();
    const VIEWLEGS = "VIEWLEGS";
    useEffect(() => {
        if (!Permissions([VIEWLEGS])) {
            navigate(`/`);
            clearAll();
        }
    }, []);

    const closeSidebar = () => {
        if (window.innerWidth > 767) return;
        document.getElementById("my-sidebar").style.width = "0";
        document.getElementById("main").style.display = "block";
        document.getElementById("my-sidebar").style.marginLeft = "-25px";
    };

    const url = useHref();
    return (
        <div className="icreon-sidebar">
            <div id="my-sidebar" className={`sidebar ${process.env.REACT_APP_SSO_KEY === "stage" ? "green-bg" : ""}`}>
                <div className="mobile-sidebar-header">
                    <div className="sidebar-logo-sec">
                        <img src={SidebarLogo} alt="logo" />
                    </div>
                    <a href="javascript:void(0)" className="header-close-btn" onClick={closeSidebar}></a>
                </div>
                <div className="mobile-sidebar-links-group">
                    <div className={`left-menu-link ${url == "/" ? "active" : ""} `} onClick={() => navigate(`/`)}>
                        <span className="menu1 nav-icon"></span>
                        <label>Dashboard</label>
                    </div>
                    <div
                        className={`left-menu-link ${url == "/leg-list" ? "active" : url.includes("/scenario-builder") ? "active" : url.includes("/send-to-broker") ? "active" : ""} `}
                        onClick={() => navigate(`/leg-list`)}
                    >
                        <span className="menu3 nav-icon"></span>
                        <label>Schedule</label>
                    </div>
                    {Permissions(["VIEWAIRPORT", "VIEWAIRCRAFT", "VIEWOPERATOR"]) && (
                        <div className="left-menu-link">
                            <PanelBar className="sub-dropdown-menu">
                                <PanelBarItem
                                    expanded={false}
                                    title={
                                        <>
                                            <span className="menu4 nav-icon"></span>
                                            <label>Aviation</label>
                                        </>
                                    }
                                >
                                    <div className="left-sub-menu">
                                        <ul>
                                            {Permissions(["VIEWAIRPORT"]) && (
                                                <li
                                                    onClick={() => {
                                                        window.open(`${process.env.REACT_APP_FE_MAVERICK}airports-list`, "_blank");
                                                        closeSidebar();
                                                    }}
                                                >
                                                    Airports
                                                </li>
                                            )}
                                            {Permissions(["VIEWAIRCRAFT"]) && (
                                                <li
                                                    onClick={() => {
                                                        window.open(`${process.env.REACT_APP_FE_MAVERICK}aircrafts-list`, "_blank");
                                                        closeSidebar();
                                                    }}
                                                >
                                                    Aircraft
                                                </li>
                                            )}
                                            {Permissions(["VIEWOPERATOR"]) && (
                                                <li
                                                    onClick={() => {
                                                        window.open(`${process.env.REACT_APP_FE_MAVERICK}operators-list`, "_blank");
                                                        closeSidebar();
                                                    }}
                                                >
                                                    Operators
                                                </li>
                                            )}
                                            {Permissions(["VIEWFBO"]) && (
                                                <li
                                                    onClick={() => {
                                                        window.open(`${process.env.REACT_APP_FE_MAVERICK}fbo-list`, "_blank");
                                                        closeSidebar();
                                                    }}
                                                >
                                                    FBO
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </PanelBarItem>
                            </PanelBar>
                        </div>
                    )}
                </div>
            </div>
            <div id="main">
                <div className="wrapper">{children}</div>
            </div>
        </div>
    );
};

export default Sidebar;
