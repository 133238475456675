import React, { useEffect } from "react";

import { useKnockFeed } from "@knocklabs/react-notification-feed";
import { shallow } from "zustand/shallow";
import { useNotificationStore } from "store/AppNotificationStore";
import useAxiosPost from "hooks/useAxiosPost";
import { Urls } from "helpers/api-urls";
import { useUserStore } from "store/Userdetails";
const NotificationProvider = () => {
    const { userData } = useUserStore(
        (state) => ({
            userData: state.userData,
        }),
        shallow
    );
    const { updateNotification, notifications, totalUnread, totalLength, primaryNotifications } = useNotificationStore(
        (state) => ({
            updateNotification: state.updateNotification,
            notifications: state.notifications,
            totalUnread: state.totalUnread,
            totalLength: state.totalLength,
            primaryNotifications: state.primaryNotifications,
        }),
        shallow
    );
    const { feedClient } = useKnockFeed();
    const setNotification = (notifications) => {
        updateNotification({
            notifications,
        });
    };

    const setTotalUnread = (totalUnread) => {
        updateNotification({
            totalUnread,
        });
    };

    useEffect(() => {
        // Receive all real-time notifications on our feed
        feedClient.on("items.received.realtime", () => {
            fetchNotifications("get", `${Urls.Contactnotifications}/${userData.data.responseData.userData.user[0].id}?size=50&page=0`, "");
        });
        // Cleanup
        return () =>
            feedClient.off("items.received.realtime", () => {
                fetchNotifications("get", `${Urls.Contactnotifications}/${userData.data.responseData.userData.user[0].id}?size=50&page=0`, "");
            });
    }, [feedClient]);
    const { loadingPostData: notificationsLoading, sendRequest: fetchNotifications, data: notificationsList } = useAxiosPost();

    useEffect(() => {
        fetchNotifications("get", `${Urls.Contactnotifications}/${userData.data.responseData.userData.user[0].id}?size=50&page=0`, "");
    }, []);

    useEffect(() => {
        if (notificationsList) {
            updateNotification({
                notifications: notificationsList.responseData.page.content,
                totalLength: notificationsList.totalRecords,
                totalUnread: notificationsList?.responseData.unreadNotificationCount,
                primaryNotifications: notificationsList.responseData.page.content,
            });
        }
    }, [notificationsList]);
    return <React.Fragment></React.Fragment>;
};

export default NotificationProvider;
