import React from "react";
import { ErrorMessage, useField } from "formik";
import { Input } from "@progress/kendo-react-inputs";

export const TextField = ({ label, hideErrorMessage, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className="form-group">
            {/* <label htmlFor={field.name}>{label}</label> */}
            <Input className={`${"custom-input  input-height-44"} form-control ${meta.touched && meta.error && "is-invalid"}`} {...field} label={label} {...props} autoComplete="off" />
            {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={field.name} className="error" />}
        </div>
    );
};
