import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import axios from "axios";
import { persist } from "zustand/middleware";

const masterRolesApiStore = immer((set, get) => ({
    getAllRoles: [],
    getAllRolesMasterApi: async (method, url, code, config, cb = false) => {
        if (get().getAllRoles?.data?.responseData?.length && !cb) {
            set({ getAllRoles: get()?.getAllRoles });
        } else {
            const apiResponse = await axios[method](url, code, config);
            set({ getAllRoles: apiResponse });
            return apiResponse;
        }
    },
}));

const persistConfig = {
    name: "getAllRoles",
    onSet: (state) => state,
};

export const useMasterRolesApiStore = create(devtools(persist(masterRolesApiStore, persistConfig)));
