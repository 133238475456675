import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const CustomTooltip = ({ placement, description, children, hide = false ,isMultiLine = false }) => {
    if(!description) return children
    return (
        <>
            {hide && (
                <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                        <Tooltip className="hidetooltip" id={`tooltip-${placement}`}>
                            {description}
                        </Tooltip>
                    }
                >
                    <div>{children}</div>
                </OverlayTrigger>
            )}
            {!hide && (
                <OverlayTrigger key={placement} placement={placement} overlay={<Tooltip className={isMultiLine && "custom-wrapped-truncated-line3"} id={`tooltip-${placement}`}>{description}</Tooltip>}>
                    <div  className = {isMultiLine ? "custom-wrapped-truncated-line3" : "custom-wrapped-truncated"}>{children}</div>
                </OverlayTrigger>
            )}
        </>
    );
};

export default CustomTooltip;
