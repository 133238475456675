import { Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { customeRoutes } from "./lazy-load";
// import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
// import ProtectedComponent from "./protectedComponent";
import Loader from "../components/common/Loader";
import Layout from "./checkIsUserAuthenticated";
import UserManagement from "pages/SystemManagement/UserManagement";
import RoleManagementScreen from "pages/SystemManagement/RoleManagementScreen";
import ProtectedComponent from "./protectedComponent";
import ErrorBoundary from "components/common/ErrorBoundary";
import OperatorsList from "pages/Aviation/OperatorsList";
import AircraftsList from "pages/Aviation/AircraftsList";

export default function Navigation(props) {
    return (
        <div>
            <div>
                <ErrorBoundary>
                    <Suspense fallback={<Loader />}>
                        <Routes>
                            <Route path="/login" element={<customeRoutes.LoginScreen />} />
                            <Route path="/" element={<Layout />}>
                                <Route path="/" element={<ProtectedComponent component={<customeRoutes.Dashboard />} />} />
                                <Route path="/" element={<customeRoutes.Dashboard />} />
                                <Route path="/management/" element={<customeRoutes.SystemManagementScreen />}>
                                    <Route path="user-management" element={<UserManagement />} />
                                    <Route path="role-management" element={<RoleManagementScreen />} />
                                    <Route path="permissions-management" element={<UserManagement />} />
                                    <Route path="my-profile" element={<UserManagement />} />
                                </Route>
                                <Route path="/user-permission/:id" element={<customeRoutes.UserPermissionsScreen />} />
                                <Route path="/edit-role/:roleId" element={<customeRoutes.EditRole />} />
                                <Route path="/create-role" element={<customeRoutes.EditRole />} />
                                <Route path="/leg-list" element={<customeRoutes.LegListing />} />
                                <Route path="/scenario-builder/:id/send-to-broker/:brokerName" element={<customeRoutes.SendToBroker />} />
                                <Route path="/scenario-builder/:id" element={<customeRoutes.ScenarioBuilder />} />
                                <Route path="/notes-panel" element={<customeRoutes.NotesPanel />} />
                                <Route path="/aviation/" element={<customeRoutes.AviationScreen />}>
                                    <Route path="aircrafts" element={<AircraftsList />} />
                                    <Route path="operators" element={<OperatorsList />} />
                                </Route>
                            </Route>
                        </Routes>
                    </Suspense>
                </ErrorBoundary>
            </div>
            {/* <AuthenticatedTemplate>
        <Sidebar>
          <Header />
          <div style={{paddingTop:70}}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
            <Route path="/" element={<customeRoutes.HomeSreen />} />
              <Route path="/team-management" element={<customeRoutes.URTManagement />} />
              <Route path="/create-team" element={<customeRoutes.CreateTeamScreen />} />
              <Route path="/team" element={<customeRoutes.TeamScreen />} />
              <Route path="/team-list" element={<customeRoutes.TeamList />} />
              <Route  path="/create-role" element={<customeRoutes.CreateRoleScreen />} />
              <Route  path="/edit-role" element={<customeRoutes.CreateRoleScreen />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          </div>
        </Sidebar>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/" element={<customeRoutes.LoginScreen />} />
        </Routes>
      </UnauthenticatedTemplate> */}
        </div>
    );
}
function NotFound() {
    return <h2>NotFound</h2>;
}
