import { Component } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function withRouter(Child) {
    return (props) => {
        const location = useLocation();
        const navigate = useNavigate();
        return <Child {...props} navigate={navigate} location={location} />;
    };
}

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // eslint-disable-next-line no-console
        if (process.env.NODE_ENV !== "production") console.log(error, errorInfo);
    }
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname && this.state.hasError) {
            this.setState({ hasError: false });
        }
    }
    render() {
        const {
            state: { hasError },
            props: { fallback, children },
        } = this;

        if (hasError) {
            // You can render any custom fallback UI
            return (
                fallback || (
                    <div className="py-5 px-3 text-center">
                        <div className="py-5 card">
                            <div className="h1 my-2">Something went wrong...</div>
                            <div className="h4 my-2">We're sorry to see this but there's some technical issue at our end.</div>
                            <div className="h4 my-2">
                                Please either{" "}
                                <strong className="anchor-tag" onClick={() => window.location.reload()}>
                                    Refresh
                                </strong>{" "}
                                this page or go to another Page. We're looking into this issue...
                            </div>
                        </div>
                    </div>
                )
            );
        }

        return children;
    }
}
export default withRouter(ErrorBoundary);
