import React, { useEffect, useState } from "react";
import "assets/css/RoleManagement.scss";
import { Card } from "@progress/kendo-react-layout";
import PersonIcon from "assets/images/person.svg";
import { FaTrashAlt } from "react-icons/fa";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { defaultErrorMessage, defaultSuccessMessage, formateDate } from "helpers";
import ModalPopup from "components/common/Modal";
import { useNavigate } from "react-router-dom";
import { useMasterRolesApiStore } from "store/MasterRolesDataStore";
import { shallow } from "zustand/shallow";
import { useuserFilterTableStore } from "store/UserManagementStore";
import { Urls, buildURL } from "helpers/api-urls";
import useAxiosPost from "hooks/useAxiosPost";
import { CommonSuccessMessage, DeletePopupModalDescription, SomethingWentWrong } from "helpers/constants";
import { toast } from "react-toastify";

const toolTipDescription = (des) => {
    return (
        <Tooltip openDelay={100} className="gridsTooltip" anchorElement={"pointer"}>
            <span title={des}>{des.length > 120 ? "..." : ""}</span>
        </Tooltip>
    );
};
const RoleManagementScreen = () => {
    const navigate = useNavigate();

    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
    const [roleId, setRoleId] = useState(null);
    const [refresh, setRefresh] = useState(0);

    const handleCloseDeleteRoleModal = () => setShowDeleteRoleModal(false);
    const handleShowDeleteRoleModal = () => setShowDeleteRoleModal(true);
    const { rolesMasterData, getAllRolesMasterApiFunc } = useMasterRolesApiStore(
        (state) => ({
            rolesMasterData: state.getAllRoles,
            getAllRolesMasterApiFunc: state.getAllRolesMasterApi,
        }),
        shallow
    );
    const { onClickApplyRoleFilterFromGridFunc } = useuserFilterTableStore(
        (state) => ({
            onClickApplyRoleFilterFromGridFunc: state.onClickApplyRoleFilterFromGrid,
        }),
        shallow
    );

    const { loadingPostData, sendRequest, data } = useAxiosPost();

    const deleteRole = async () => {
        await sendRequest("delete", `${Urls.DeleteRole}/${roleId}`, {});
    };

    const onCardClickHandler = (roleData, totalUsers) => {
        if (!totalUsers) return;
        onClickApplyRoleFilterFromGridFunc(roleData);
        navigate("/management/user-management");
    };

    useEffect(() => {
        if (data?.responseCode === 200) {
            toast.success(CommonSuccessMessage(), defaultSuccessMessage);
            setRefresh((prev) => prev + 1);
            handleCloseDeleteRoleModal();
        }
        if (data?.responseCode === 400) toast.error(SomethingWentWrong, defaultErrorMessage);
    }, [data]);

    useEffect(() => {
        getAllRolesMasterApiFunc("get", buildURL(Urls.MasterApiGetAllRoles), {}, {}, true);
    }, [getAllRolesMasterApiFunc, refresh]);

    let type = "Role Management";
    return (
        <div>
            {" "}
            <div className="user-details-cards-group mt-20">
                <div className="user-details-card create-new-team" onClick={() => navigate("/create-role")}>
                    <div className="create-team">
                        <a className="btn btn-link">Create New {type == "Role Management" ? "Role" : "Team"}</a>
                    </div>
                </div>
                {rolesMasterData?.data?.responseData?.map((role, i) => {
                    return (
                        <Card
                            className="user-details-card"
                            key={i}
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/edit-role/${role?.aclRoleId}`, {
                                    state: { viewMode: false },
                                });
                            }}
                        >
                            <div className="team-tile-sec">
                                <h4>
                                    <strong>{role?.roleName}</strong>
                                </h4>
                                <div className="card-action">
                                    <FaTrashAlt
                                        className="role-trash-button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowDeleteRoleModal(true);
                                            setRoleId(role?.aclRoleId);
                                        }}
                                    />
                                    <Button
                                        className="edit-icon"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/edit-role/${role?.aclRoleId}`, {
                                                state: { viewMode: true },
                                            });
                                        }}
                                    />
                                    <div
                                        className="edit-title cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onCardClickHandler(role, role?.totalUsers);
                                        }}
                                        title={role?.totalUsers ? `Click to view the users` : ``}
                                    >
                                        <span style={{ fontSize: 11 }}>{role?.totalUsers || 0}</span> <img className="cursor-pointer" alt="" src={PersonIcon} />
                                    </div>
                                </div>
                            </div>

                            <p className="about-user">
                                <div className="description">{`${role?.description.slice(0, 100)}`}</div>
                                {toolTipDescription(role.description)}
                            </p>
                            <div className="created-modified-date hide-mob">
                                <label>Created Date: {formateDate(new Date(role?.createdDate))}</label>
                                <label>Last Modified Date: {formateDate(new Date(role?.updatedDate))}</label>
                            </div>
                            <div className="created-modified-date show-mob">
                                <label>Created: {formateDate(new Date(role?.createdDate))}</label>
                                <label>Modified: {formateDate(new Date(role?.updatedDate))}</label>
                            </div>
                        </Card>
                    );
                })}
            </div>
            <ModalPopup
                handleShow={handleShowDeleteRoleModal}
                show={showDeleteRoleModal}
                handleClose={handleCloseDeleteRoleModal}
                description={DeletePopupModalDescription}
                title={"Delete Role"}
                actionClassname
                closeButtonTitle={"No, Keep Active"}
                actionButtonTitle={"Yes, Delete"}
                onClick={deleteRole}
            />
        </div>
    );
};

export default RoleManagementScreen;
