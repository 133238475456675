import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import axios from "axios";
import { persist, createJSONStorage } from "zustand/middleware";

export const userFilterTableStore = (set, get) => ({
    name: "",
    createdEndDate: "",
    createdStartDate: "",
    status: [],
    role: [],
    sort: [{ field: "name", dir: "asc" }],

    sortUserGridList: (e) => {
        set(() => ({
            sort: e.sort,
        }));
    },
    resetUserGridFilter: () => {
        set(() => ({ name: "", createdEndDate: "", createdStartDate: "", status: [], role: [], sort: [{ field: "name", dir: "asc" }] }));
    },

    removeQuickFilter: (filterName) => {
        set(() => ({
            [filterName]: "",
        }));
    },
    removeRole: (roleName, id) => {
        set((state) => ({
            role: state.role?.filter((filteredRole) => filteredRole.aclRoleId !== id),
        }));
    },
    removeStatus: (statusName, id) => {
        set((state) => ({
            status: {},
        }));
    },
    lastAppliedFilter: (prevFilters) => {
        set((state) => ({ lastAppliedFilters: prevFilters }));
    },
    onClickApplyFilter: (data) => {
        set((state) => ({
            name: data?.name || "",
            createdEndDate: data?.createdEndDate || null,
            createdStartDate: data?.createdStartDate || null,
            sort: data?.sort || [{ field: "name", dir: "asc" }],
            status: data?.status || [],
            role: data?.role || [],
        }));
    },
    onClickApplyRoleFilterFromGrid: (roleData) => {
        set((state) => ({
            role: [roleData],
        }));
    },
});
export const useuserFilterTableStore = create(
    persist(userFilterTableStore, {
        name: "userListGrid",
    })
);
