export const SomethingWentWrong = "Oops ! Something went wrong";
export const SuccessMessage = (action) => `Success! ${action} has been created`;
export const SuccessSchedulerAssignedMessage = (action) => `Success! Scheduler has been assigned`;
export const SuccessOperatorNoteMessage = (action) => `Success! Operator note added successfully`;
export const SuccessOperatorNoteRemoveMessage = (action) => `Success! Operator note removed successfully`;
export const SuccessStatusChangedMessage = (action = "") => `Success! Status has been changed`;
export const SuccessSchedulerUnassignMessage = (action) => `Success! Scheduler has been unassigned`;
export const SuccessAllSchedulerUnassignMessage = (action) => `Success! All Schedulers has been unassigned`;
export const SuccessLinkLegessage = (action) => `Legs Successfully Linked.`;
export const SuccessPriorityMessage = (action) => `Success! Priority has been assigned`;
export const SuccessSchedulerSubStatusMessage = (action = "") => `Success! Sub Status has been ${action ? action : "assigned"}`;
export const UpdateSuccessMessage = (action) => `Success! ${action} has been updated`;
export const DeletedSuccessMessage = (action) => `Success! ${action} has been deleted`;
export const CommonSuccessMessage = () => `Changes Saved`;
export const AlreadyExistsMessage = (action) => `${action} already exists!`;
export const TripIDCopiedMessage = () =>  `Trip ID Copied`
export const DeletePopupModalDescription = "Are you sure to Delete? This activity can’t be undone";
export const CancelModalPopupDescription = "Do you want to Cancel? Any unsaved data will be Lost.";
export const BackToPreviousDescritpion = "Do you want to Leave the page? Any unsaved data will be Lost.";
export const DeletePopupModalDesc = "Are you sure to Delete? This activity can’t be undone";
export const DeletePopupModalCommon = "Are you sure to Delete? This file can’t be undone";
export const DeleteScenarioModalCommon = "Are you sure to Delete? This scenario can’t be undone";
export const DeleteScenarioOperatorMessage = "Are you sure to Delete? This action can't be undone";
export const DeleteNoteModal = "Are you sure you want to permanently delete this note from the system?";
export const priorityOrder = {
    "Mechanical Issue (MX)": 1,
    "Cancellation (CX)": 2,
    Matchup: 3,
    Queue: 4,
    Inactive: 5,
    Active: 6,
    New: 8,
};

export const tripStatusIdIsAfterAccepted = [4, 5, 7, 13, 15, 16, 21, 8, 9, 10, 11]