// utils/visibility.js
export const addVisibilityChangeListener = (callback) => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        callback(true); // Tab is active
      } else {
        callback(false); // Tab is inactive
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    // Return a function to remove the event listener
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  };
  