import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { shallow } from "zustand/shallow";
import useAxiosPost from "hooks/useAxiosPost";
import { Urls } from "helpers/api-urls";
import startShedulerWhite from "assets/images/start-sheduler-white.svg";
import { Button } from "@progress/kendo-react-buttons";
import { useTripProfileStore } from "store/TripProfileStore";
import JetCardProfile from "./JetCardProfile";
import CharterTypeProfile from "./CharterTypeProfile";
import LoaderInner from "components/common/LoaderInner";
import { useNavigate } from "react-router-dom";
import { useClientProfileStore } from "store/ClientProfileStore";

const TripProfile = () => {
    const [loader, setLoader] = useState(true);
    const [refresh, setRefresh] = useState(0);
    const navigate = useNavigate();
    const { setOpenTripProfileView, tripId, legId, openTripData, setIsRefreshLegListFromTripView , setLegId, isRefreshLegListFromTripView, dataSourceIdForTrip } = useTripProfileStore(
        (state) => ({
            setOpenTripProfileView: state.setOpenTripProfileView,
            tripId: state.tripId,
            legId: state.legId,
            setLegId : state.setLegId,
            setIsRefreshLegListFromTripView: state.setIsRefreshLegListFromTripView,
            isRefreshLegListFromTripView: state.isRefreshLegListFromTripView,
            dataSourceIdForTrip: state.dataSourceIdForTrip,
            openTripData: state.openTripData,
        }),
        shallow
    );
    const { openClientProfile, setOpenClientProfileView, clientId } = useClientProfileStore(
        (state) => ({
            openClientProfile: state.openClientProfile,
            setOpenClientProfileView: state.setOpenClientProfileView,
            clientId: state.clientId,
        }),
        shallow
    );
    const { loadingPostData, sendRequest, data, status } = useAxiosPost();
    const {
        loadingPostData: loadingClientInfoForMarginAndPriorityDetailsLastTrip,
        sendRequest: sendRequestToGetClientInfoForMarginAndPriorityLastTrip,
        data: clientInfoForMarginAndPriorityTripDataFromPhoenix,
    } = useAxiosPost();
    const { loadingPostData: loadingSpecialRequests, sendRequest: sendRequestToGetSpecialRequests, data: specialRequestsData } = useAxiosPost();

    useEffect(() => {
        (async () => {
            setLoader(true);
            await sendRequest("get", `${Urls.GetTripDetails(dataSourceIdForTrip)}`, {}, "MV");
            await sendRequestToGetClientInfoForMarginAndPriorityLastTrip("get", `${Urls.GetClientMarginAndPriorityDetails(tripId)}`);
            await sendRequestToGetSpecialRequests("get", `${Urls.GetSpecialRequests(dataSourceIdForTrip)}`, {}, "MV");
        })();
    }, [tripId, refresh, dataSourceIdForTrip]);

    useEffect(() => {
        if (setOpenClientProfileView) {
            document.body.classList?.add("hide-scroll-on-modal-open");
            return () => {
                document.body.classList?.remove("hide-scroll-on-modal-open");
            };
        }
    }, [setOpenClientProfileView]);
    useEffect(() => {
        if (specialRequestsData) {
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }, [specialRequestsData]);
    return (
        <div>
            {" "}
            <Dialog
                autoFocus={true}
                title={"TRIP OVERVIEW"}
                onClose={() => {
                    setOpenTripProfileView(false);
                    setLegId("")
                }}
                className="add-contact-details-model edit-contact-modal contact-view-modal right-open-modal w-750px"
                style={{ height: 1000, overflow: "scroll" }}
            >
                {loader && <LoaderInner />}
                <>
                    {data?.responseData?.tripType?.displayName === "Jet Card" && (
                        <JetCardProfile
                            data={data}
                            legId={legId ? legId : data?.responseData?.legs.length ? data.responseData.legs[0].id : ""}
                            margin={clientInfoForMarginAndPriorityTripDataFromPhoenix?.responseData?.margin}
                            cancellationTerms={clientInfoForMarginAndPriorityTripDataFromPhoenix?.responseData?.cancellationTerms || ""}
                            clientInfoForMarginAndPriorityTripDataFromPhoenix={clientInfoForMarginAndPriorityTripDataFromPhoenix?.responseData?.tripLegsResult}
                            setRefresh={setRefresh}
                            setIsRefreshLegListFromTripView={setIsRefreshLegListFromTripView}
                            isRefreshLegListFromTripView={isRefreshLegListFromTripView}
                            specialRequestsData={specialRequestsData}
                            openTripData={openTripData}
                        />
                    )} 
                    {data?.responseData?.tripType?.displayName === "Charter" && (
                        <CharterTypeProfile
                            data={data}
                            legId={legId ? legId : data?.responseData?.legs.length ? data.responseData.legs[0].id : ""}
                            margin={clientInfoForMarginAndPriorityTripDataFromPhoenix?.responseData?.margin}
                            clientInfoForMarginAndPriorityTripDataFromPhoenix={clientInfoForMarginAndPriorityTripDataFromPhoenix?.responseData?.tripLegsResult}
                            setRefresh={setRefresh}
                            setIsRefreshLegListFromTripView={setIsRefreshLegListFromTripView}
                            isRefreshLegListFromTripView={isRefreshLegListFromTripView}
                            specialRequestsData={specialRequestsData}
                            openTripData={openTripData}
                        />
                    )}
                    {!loader && (
                        <div className="model-footer-action">
                            <Button
                                className="height-42 mob-width-full btn-large-padding border-0 fs-14 font-600"
                                themeColor={"primary"}
                                onClick={() => {
                                    setOpenTripProfileView(false);
                                    setOpenClientProfileView(false);
                                    navigate(`/scenario-builder/${tripId}`);
                                    setLegId("")
                                }}
                            >
                                Schedule Trip <img src={startShedulerWhite} alt="Sheduler Icon" />
                            </Button>
                        </div>
                    )}
                </>
            </Dialog>
        </div>
    );
};

export default TripProfile;
