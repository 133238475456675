import { Loader as TableSpinner } from "@progress/kendo-react-indicators";
import { divIcon } from "@progress/kendo-svg-icons";

export default function TableLoader() {
    return (
        <div className="text-center my-3">
            <div className="table-loading"></div>
        </div>
    );
}
