import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { persist } from "zustand/middleware";

const webStore = immer((set) => ({
    webSocketData: {},
    webSocketDataDashBoard: {},
    webSocketDataDashBoardSave: {},
    setSwebSocketData: (data) => {
        set((state) => ({
            webSocketData: data,
        }));
    },
    setSwebSocketDataDashBoard: (data) => {
        set((state) => ({
            webSocketDataDashBoard: data,
            webSocketDataDashBoardSave: data
        }));
    },
    setSwebSocketDataDashBoardClear: (data) => {
        set((state) => ({
            webSocketDataDashBoard: data
        }));
    }
}));

const persistConfig = {
    name: "webSocket",
    onSet: (state) => state,
};

export const useWebSocketStore = create(devtools(persist(webStore, persistConfig)));
