import * as React from "react";
import { MultiSelectTree, getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { processMultiSelectTreeData, expandedState } from "./multiselecttree-data-operations";
import { FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useEffect } from "react";

export const CustomMultiSelect = React.forwardRef(({
    dataList,
    keyName,
    name,
    dataItemKey,
    value: initialValue,
    label,
    disabled = false,
    handleFilterName,
    setCheckIfUsermadeAnyChanges = () => {},
    removeCrossIcon = false,
    upDown = false,
}, ref) => {

    const checkField = "checkField";
    const checkIndeterminateField = "checkIndeterminateField";
    const subItemsField = "items";
    const expandField = "expanded";
    const [value, setValue] = React.useState(initialValue)
    const textField = keyName;
    const fields = {
        dataItemKey,
        checkField,
        checkIndeterminateField,
        expandField,
        subItemsField,
    };
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    React.useImperativeHandle(ref, () => ({
        setValue , value
    }))
    const popupRef = React.useRef(null);
    const [expanded, setExpanded] = React.useState([dataList[0]?.[dataItemKey]]);
    const [opened, setOpened] = React.useState(false); // Added state for dropdown open/close

    const [filter, setFilter] = React.useState(null);
    const onChange = (event) => {
        setOpened(true);
        setCheckIfUsermadeAnyChanges(true);
        return handleFilterName(
            getMultiSelectTreeValue(dataList, {
                ...fields,
                ...event,
                value,
            }),
            event
        );
    };
    const onExpandChange = React.useCallback((event) => setExpanded(expandedState(event.item, dataItemKey, expanded)), [expanded]);
    const treeData = React.useMemo(
        () =>
            processMultiSelectTreeData(dataList, {
                expanded,
                value,
                filter,
                ...fields,
            }),
        [dataList, expanded, value, filter]
    );
    const onFilterChange = (event) => {
        setFilter(event.filter);
    };

    const onClose = () => {
        setFilter({ value: "" });
    };
    const toggleDropdown = () => {
        setOpened(!opened);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target) && !event.target.closest(".k-animation-container")) {
                setOpened(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [opened]);
    const showCrossButton = Array.isArray(value) && value.length > 0;
    // const updatedTreeData = !filter?.value ? treeData : treeData.filter((f) => f[keyName]?.toLowerCase()?.includes(filter?.value));
    return (
        <div
            className="position-relative"
            ref={popupRef}
            onClick={() => {
                // if (!Boolean(disabled)) {
                toggleDropdown();
                // }
            }}
        >
            <span className={`k-floating-label-container`}>
                {!removeCrossIcon && (
                    <>
                        {upDown && !opened && (
                            <FaChevronDown
                                className="position-absolute swc-ms-up-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleDropdown();
                                }}
                            />
                        )}
                        {upDown && opened && (
                            <FaChevronUp
                                className="position-absolute swc-ms-down-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleDropdown();
                                }}
                            />
                        )}
                        {showCrossButton && (
                            <FaTimes
                                className="position-absolute swc-ms-cross-button"
                                onClick={(e) => {
                                    document.body.click();
                                    e.stopPropagation();
                                    handleFilterName([]);
                                }}
                            />
                        )}
                    </>
                )}
                <MultiSelectTree
                    data={treeData}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder="Please select ..."
                    textField={textField}
                    dataItemKey={dataItemKey}
                    checkField={checkField}
                    checkIndeterminateField={checkIndeterminateField}
                    expandField={expandField}
                    subItemsField={subItemsField}
                    onExpandChange={onExpandChange}
                    filterable={true}
                    onFilterChange={onFilterChange}
                    disabled={disabled}
                    filter={filter?.value}
                    onClose={onClose}
                    opened={opened}
                />
                <label className="k-label">{label}</label>
            </span>
        </div>
    );
})
