import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export const CustomSingleSelect = ({ dataList, handleFilterName, value, label, dateItemKey, keyName, textField, popupSettings }) => {
    const handleChange = (event) => {
        handleFilterName(event);
    };

    return (
        <div>
            {dataList.length > 0 && (
                <DropDownList
                    data={dataList}
                    value={value}
                    popupSettings={popupSettings}
                    onChange={handleChange}
                    label={label}
                    className="custom-input"
                    dataItemKey={dateItemKey}
                    keyName={keyName}
                    textField={textField}
                />
            )}
        </div>
    );
};
