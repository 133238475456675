import React, { useEffect, useReducer, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { Link } from "react-router-dom";
import FilterIcon from "assets/images/icon-ExpandDown.svg";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomMultiSelect } from "components/common/CustomMultiSelect";
import TableLoader from "components/common/TableLoader";
import { useuserFilterTableStore } from "store/UserManagementStore";
import { shallow } from "zustand/shallow";
import QuickRecentFilters from "./QuickRecentFilters";
import { formateDate, formateDateFilters } from "helpers";
import { CustomSingleSelect } from "components/common/CustomSingleSelect";
import { orderBy } from "@progress/kendo-data-query";
import RenderUserStatus from "components/common/RenderUserStatus";
import { useMasterRolesApiStore } from "store/MasterRolesDataStore";
import { Urls, buildURL } from "helpers/api-urls";
import PageTitle from "components/common/PageTitle";
import useAxiosPost from "hooks/useAxiosPost";
import { Error } from "@progress/kendo-react-labels";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CustomTooltip from "components/common/CustomTooltip";

const UserManagement = () => {
    const { name, createdStartDate, createdEndDate, roleFilter, statusFilter, sort, setResetUserGridFiltersFunc, setUserGridSortFunc, setLastAppliedFilterFunc, setOnClickApplyFilterFunc } =
        useuserFilterTableStore(
            (state) => ({
                name: state.name,
                createdEndDate: state.createdEndDate,
                createdStartDate: state.createdStartDate,
                roleFilter: state.role,
                statusFilter: state.status,
                sort: state.sort,
                filtersApplied: state.filtersApplied,
                lastAppliedFilters: state.lastAppliedFilters,
                setResetUserGridFiltersFunc: state.resetUserGridFilter,
                setUserGridSortFunc: state.sortUserGridList,
                setLastAppliedFilterFunc: state.lastAppliedFilter,
                setOnClickApplyFilterFunc: state.onClickApplyFilter,
            }),
            shallow
        );
    const { rolesMasterData, getAllRolesMasterApiFunc } = useMasterRolesApiStore(
        (state) => ({
            rolesMasterData: state.getAllRoles,
            getAllRolesMasterApiFunc: state.getAllRolesMasterApi,
        }),
        shallow
    );
    const [checkIfUsermadeAnyChanges, setCheckIfUsermadeAnyChanges] = useState(false);
    const [onCancel, setOnCancel] = useState(0);
    const [userListFilterInput, setUserListFilterInput] = useReducer((state, newState) => ({ ...state, ...newState }), {
        name: name || "",
        createdEndDate: createdEndDate || "",
        createdStartDate: createdStartDate || "",
        status: statusFilter || [],
        role: roleFilter || [],
        sort: sort || [{ field: "name", dir: "asc" }],
        filtersApplied: false,
        lastAppliedFilters: {},
    });
    const headerQuickSelectedFilters = [
        { name, type: "name" },
        { createdStartDate: createdStartDate, type: "createdStartDate" },
        { createdEndDate, type: "createdEndDate" },
        { roleFilter, type: "roleFilter" },
        { statusFilter, type: "statusFilter" },
        { sort, type: "sort" },
    ];
    const [toggleFilter, setToggleFilter] = useState(false);
    const [gridData, setGridData] = useState({
        itemsDisplayed: 10,
        data: [],
    });
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isCreatedStartDateInValid, setIsCreatedStartDateInValid] = useState(false);
    const [isCreatedEndDateInValid, setIsCreatedEndDateInValid] = useState(false);
    const statusData = [
        { status: "Active", id: 1 },
        { status: "Inactive", id: 2 },
    ];

    const { loadingPostData, sendRequest, data, status } = useAxiosPost();

    const applyUserListFilters = async () => {
        setOnClickApplyFilterFunc(userListFilterInput);
        setRefresh((prev) => prev + 1);
        setGridData((prev) => ({ ...prev, itemsDisplayed: 10 }));
        setLastAppliedFilterFunc({ name, createdStartDate, createdEndDate, role: roleFilter, status: statusFilter, sort });
        setToggleFilter(false);
    };
    useEffect(() => {
        setIsCreatedStartDateInValid(false);
        setIsCreatedEndDateInValid(false);
    }, [toggleFilter]);
    useEffect(() => {
        setUserListFilterInput({
            name: name || "",
            createdEndDate: createdEndDate || "",
            createdStartDate: createdStartDate || "",
            status: statusFilter || [],
            role: roleFilter || [],
            sort: sort || [],
            filtersApplied: false,
            lastAppliedFilters: {},
        });
    }, [onCancel]);

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            applyUserListFilters();
        }
    };
    useEffect(() => {
        (async () => {
            const payload = {
                name,
                startDate: createdStartDate ? formateDateFilters(createdStartDate) : null,
                endDate: createdEndDate ? formateDateFilters(createdEndDate) : null,
                status: statusFilter?.id || "",
                roles: roleFilter?.map((role) => role?.roleName).toString(),
            };
            await sendRequest("post", `${Urls.GetUserDataWithFilters}`, payload);
        })();
    }, [refresh]);

    const availableProducts = Array.isArray(data?.responseData) ? data?.responseData.slice() : [];

    const scrollHandler = async (event) => {
        const e = event.nativeEvent;
        if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
            if (gridData.itemsDisplayed <= availableProducts.length) {
                setLoading(true);
                await new Promise((resolve) => setTimeout(resolve, 1000));
                setGridData((prev) => ({
                    itemsDisplayed: gridData.itemsDisplayed + 10,
                    data: availableProducts.slice(0, gridData.itemsDisplayed + 10),
                }));
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (status) {
            setLoading(false);
        }
    }, [status]);

    const currentGridData = { ...gridData, data: availableProducts.slice(0, gridData.itemsDisplayed) };

    const sortedData = orderBy(currentGridData?.data, sort);
    useEffect(() => {
        getAllRolesMasterApiFunc("get", buildURL(Urls.MasterApiGetAllRoles));
    }, []);

    const handleUserListGridFilter = (name, value) => {
        switch (name) {
            default:
                setUserListFilterInput({ [name]: value });
                break;
        }
    };
    const handleOnBlurCreatedStartDate = (evt) => {
        const selectedDate = evt.target?.value?.toLowerCase();
        if (!selectedDate || selectedDate === "mm/dd/yyyy") {
            return;
        }
        const currentDate = new Date();
        const enteredDate = new Date(selectedDate);
        const isInValid = selectedDate.indexOf("d") > -1 || selectedDate.indexOf("m") > -1 || selectedDate.indexOf("y") > -1 || selectedDate.indexOf("0000") > -1 || enteredDate > currentDate;
        setIsCreatedStartDateInValid(isInValid);
    };
    const handleOnBlurCreatedEndDate = (evt) => {
        const selectedDate = evt?.target?.value?.toLowerCase();
        if (!selectedDate || selectedDate === "mm/dd/yyyy") {
            return;
        }
        const currentDate = new Date();
        const enteredDate = new Date(selectedDate);
        const isInValid = selectedDate.indexOf("d") > -1 || selectedDate.indexOf("m") > -1 || selectedDate.indexOf("y") > -1 || selectedDate.indexOf("0000") > -1 || enteredDate > currentDate;
        setIsCreatedEndDateInValid(isInValid);
    };
    const [gridHeight, setGridHeight] = useState(400);

    const getHeight = (id, margen) => {
        let elm = document.getElementById(id);

        let w = window.innerHeight;
        if (margen) {
            w -= margen;
        }

        if (elm) {
            setGridHeight(w - elm.getBoundingClientRect().top - 10);

            return w - elm.getBoundingClientRect().top - 10;
        }
        setGridHeight(w);
        return w;
    };

    useEffect(() => {
        getHeight("grid1", 50);
    });
    return (
        <>
            <div>
                <PageTitle title={"System Settings"} subTitle={"All Users"} />
                <div className="data-list-view-wrapper">
                    <div className="data-grid-filter-sec">
                        <div className="filtered-btn-data">
                            <div className="filter-left-sec">
                                <Button fillMode="flat" className="filters-btn" onClick={() => setToggleFilter(!toggleFilter)}>
                                    All Filters{" "}
                                    <img
                                        style={{
                                            transform: toggleFilter ? "rotate(0deg)" : "rotate(180deg)",
                                        }}
                                        src={FilterIcon}
                                        alt=""
                                    />
                                </Button>
                            

                            <QuickRecentFilters
                                setUserListFilterInput={setUserListFilterInput}
                                headerQuickSelectedFilters={headerQuickSelectedFilters}
                                toggleFilter={toggleFilter}
                                setRefresh={setRefresh}
                                userListFilterInput={userListFilterInput}
                            />
                            </div>
                            <div className="filter-right-btn-sec">
                                <>
                                    {(name || Object.keys(statusFilter)?.length > 0 || Object.keys(roleFilter)?.length > 0 || createdEndDate || createdStartDate) && !toggleFilter && (
                                        <Button
                                            fillMode="outline"
                                            className="btn-outline-primary relative-icon-plus height-32"
                                            onClick={() => {
                                                setUserListFilterInput({
                                                    name: "",
                                                    createdEndDate: "",
                                                    createdStartDate: "",
                                                    status: [],
                                                    role: [],
                                                    sort: [{ field: "name", dir: "asc" }],
                                                    filtersApplied: false,
                                                    lastAppliedFilters: {},
                                                });
                                                setResetUserGridFiltersFunc();
                                                setRefresh((prev) => prev + 1);
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    )}
                                    {toggleFilter && (
                                        <>
                                            {" "}
                                            <Button
                                                fillMode="flat"
                                                className="text-primary height-32"
                                                onClick={() => {
                                                    setOnCancel((prev) => prev + 1);
                                                    setToggleFilter(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                fillMode="outline"
                                                className="btn-primary height-32"
                                                onClick={() => {
                                                    applyUserListFilters();
                                                }}
                                            >
                                                Apply
                                            </Button>
                                        </>
                                    )}
                                </>
                            </div>
                        </div>

                        <div className="phoenix-form mt-20 mb-10" style={{ display: toggleFilter ? "block" : "none" }}>
                            <div className="row" style={{ display: toggleFilter ? "flex" : "none" }}>
                                <div className="col-lg-4 col-sm-6 col-xl-2 mb-20">
                                    <div className="form-group">
                                        <Input
                                            type="text"
                                            name="name"
                                            onChange={(e) => handleUserListGridFilter(e.target.name, e.target.value)}
                                            label="Search By Name"
                                            className="custom-input"
                                            value={userListFilterInput?.name}
                                            onKeyDown={onKeyDownHandler}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-xl-2 mb-20">
                                    <div className="form-group">
                                        <CustomSingleSelect
                                            dataList={statusData || []}
                                            value={userListFilterInput?.status}
                                            handleFilterName={(e) => handleUserListGridFilter("status", e.target.value)}
                                            keyName="status"
                                            textField="status"
                                            name="status"
                                            dataItemKey="id"
                                            label="Search By Status"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-xl-2 mb-20">
                                    <div className="form-group">
                                        <CustomMultiSelect
                                            dataList={rolesMasterData?.data?.responseData || []}
                                            value={userListFilterInput?.role}
                                            handleFilterName={(e) => handleUserListGridFilter("role", e)}
                                            keyName="roleName"
                                            name="roleName"
                                            textField={"roleName"}
                                            dataItemKey="aclRoleId"
                                            label="Search By Role"
                                            setCheckIfUsermadeAnyChanges={setCheckIfUsermadeAnyChanges}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-xl-4">
                                    <div className="row">
                                        <div className="col-6">
                                            {toggleFilter && (
                                                <div className="form-group">
                                                    <DatePicker
                                                        formatPlaceholder={{
                                                            year: "yyyy",
                                                            day: "dd",
                                                            month: "mm",
                                                        }}
                                                        // formatPlaceholder="formatPattern"
                                                        label="Created Start"
                                                        name="createdStartDate"
                                                        onBlur={(e) => handleOnBlurCreatedStartDate(e)}
                                                        format={"MM/dd/yyyy"}
                                                        placeholder={"mm/dd/yyyy"}
                                                        className="custom-input datePicker"
                                                        onChange={(e) => handleUserListGridFilter(e.target.name, e.target.value ? new Date(e.target.value) : null)}
                                                        max={userListFilterInput?.createdEndDate ? new Date(userListFilterInput?.createdEndDate) : new Date()}
                                                        // defaultValue={createdStartDate ? new Date(createdStartDate) : null}
                                                        value={userListFilterInput?.createdStartDate ? new Date(userListFilterInput?.createdStartDate) : null}
                                                    />
                                                    {isCreatedStartDateInValid && <Error>Date selected is invalid</Error>}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            {toggleFilter && (
                                                <div className="form-group">
                                                    <DatePicker
                                                        formatPlaceholder={{
                                                            year: "yyyy",
                                                            day: "dd",
                                                            month: "mm",
                                                        }}
                                                        label="Created End"
                                                        onBlur={(e) => handleOnBlurCreatedEndDate(e)}
                                                        format={"MM/dd/yyyy"}
                                                        placeholder={"mm/dd/yyyy"}
                                                        name="createdEndDate"
                                                        onChange={(e) => handleUserListGridFilter(e.target.name, e.target.value ? new Date(e.target.value) : null)}
                                                        min={userListFilterInput?.createdStartDate ? new Date(userListFilterInput?.createdStartDate) : new Date("1970-01-01T00:00:00.000Z")}
                                                        max={new Date()}
                                                        // defaultValue={createdEndDate ? new Date(createdEndDate) : null}
                                                        className="custom-input datePicker"
                                                        value={userListFilterInput?.createdEndDate ? new Date(userListFilterInput?.createdEndDate) : null}
                                                    />
                                                    {isCreatedEndDateInValid && <Error>Date selected is invalid</Error>}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Grid
                        id="grid1"
                        style={{
                            height: `${gridHeight}px`,
                        }}
                        className="all-user-data-table detail-rows-tbl last-child-center"
                        data={sortedData}
                        onScroll={scrollHandler}
                        fixedScroll={true}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => setUserGridSortFunc(e)}
                    >
                        <GridNoRecords>{loadingPostData || loading ? <TableLoader /> : "No records available"}</GridNoRecords>
                        <Column
                            width="175px"
                            title="Name"
                            field="name"
                            className="link-col"
                            cell={(item) => {
                                const {
                                    dataItem: { name },
                                } = item;
                                return (
                                    <td className="link-col">
                                        <span className="d-inline-block">
                                            <CustomTooltip description={name} className="link-col  custom-wrapped-truncated table-truncated">
                                                {name}
                                            </CustomTooltip>
                                        </span>
                                    </td>
                                );
                            }}
                        />
                        <Column width="200px" field="email" title="Email Address" />
                        <Column
                            width="250px"
                            title="Role(s) Assigned"
                            field="aclRolePhoenix"
                            filterable={false}
                            sortable={false}
                            cell={(item) => {
                                const {
                                    dataItem: { roleStatus },
                                } = item;
                                return <td>{roleStatus?.split(",").join(", ")}</td>;
                            }}
                        />
                        <Column
                            width="120px"
                            title="Created"
                            field="createdDate"
                            cell={(item) => {
                                const {
                                    dataItem: { createdDate },
                                } = item;
                                return <td>{formateDate(createdDate)}</td>;
                            }}
                        />
                        <Column
                            width="54px"
                            field="status"
                            title="Status"
                            cell={(item) => {
                                const { dataItem } = item;
                                return (
                                    <td>
                                        <RenderUserStatus status={dataItem.status} />
                                    </td>
                                );
                            }}
                        />
                        <Column
                            width="50px"
                            filterable={false}
                            sortable={false}
                            title="Actions"
                            cell={(item) => (
                                <td className="text-center">
                                    <div className="grid-actions">
                                        <Link to={`/user-permission/${item.dataItem.userId}`}>
                                            <Button className="edit-icon-btn" fillMode="flat" />
                                        </Link>
                                    </div>
                                </td>
                            )}
                        />
                    </Grid>
                    {loading && <TableLoader />}
                    <br />
                    {/* showing: {gridData.data?.length} items */}
                </div>
            </div>
        </>
    );
};

export default UserManagement;
