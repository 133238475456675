import { create } from "zustand";

export const tripProfileStore = (set, get) => ({
    openTripProfile: false,
    tripId: "",
    legId: "",
    isRefreshLegListFromTripView: 0,
    dataSourceIdForTrip: "",
    openTripData: {},
    setOpenTripProfileView: (data) => {
        set(() => ({
            openTripProfile: data,
        }));
    },
    setTripId: (data) => {
        set(() => ({
            tripId: data,
        }));
    },
    setLegId: (data) => {
        set(() => ({
            legId: data,
        }));
    },
    setIsRefreshLegListFromTripView: (data) => {
        set((state) => ({
            isRefreshLegListFromTripView: data + 1,
        }));
    },
    setDataSourceIdForTrip: (data) => {
        set((state) => ({
            dataSourceIdForTrip: data,
        }));
    },
    setOpenTripData: (data) => {
        set((state) => ({
            openTripData: data,
        }));
    },
});
export const useTripProfileStore = create(tripProfileStore);
