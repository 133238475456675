import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { Link } from "react-router-dom";
import FilterIcon from "assets/images/icon-ExpandDown.svg";
import { Input } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomMultiSelect } from "components/common/CustomMultiSelect";
import TableLoader from "components/common/TableLoader";
import useAxios from "hooks/useAxios";
import { useuserFilterTableStore } from "store/UserManagementStore";
import { shallow } from "zustand/shallow";
import { RenderCommaSeperatedValues, formateDate } from "helpers";
import { CustomSingleSelect } from "components/common/CustomSingleSelect";
import { orderBy } from "@progress/kendo-data-query";
import RenderUserStatus from "components/common/RenderUserStatus";
import { useMasterRolesApiStore } from "store/MasterRolesDataStore";
import { Urls, buildURL } from "helpers/api-urls";
import QuickRecentFiltersOperator from "./QuickRecentFiltersOperator";
import PageTitle from "components/common/PageTitle";
import CreateOperatorWindow from "./CreateOperatorWindow";
import useToggle from "hooks/useToggle";
import CustomTooltip from "components/common/CustomTooltip";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";

const OpearatorsList = () => {
    const {
        name,
        createdStartDate,
        createdEndDate,
        roleFilter,
        statusFilter,
        sort,
        filtersApplied,
        lastAppliedFilters,
        setResetUserGridFiltersFunc,
        setUserFiltersFunc,
        setUserGridSortFunc,
        setIsUserFiltersAppliedFunc,
        setLastAppliedFilterFunc,
        setOnClickApplyFilterFunc,
    } = useuserFilterTableStore(
        (state) => ({
            name: state.name,
            createdEndDate: state.createdEndDate,
            createdStartDate: state.createdStartDate,
            roleFilter: state.role,
            statusFilter: state.status,
            sort: state.sort,
            filtersApplied: state.filtersApplied,
            lastAppliedFilters: state.lastAppliedFilters,
            setResetUserGridFiltersFunc: state.resetUserGridFilter,
            setUserFiltersFunc: state.setUserGridFilter,
            setUserGridSortFunc: state.sortUserGridList,
            setIsUserFiltersAppliedFunc: state.setIsUserFiltersApplied,
            setLastAppliedFilterFunc: state.lastAppliedFilter,
            setOnClickApplyFilterFunc: state.onClickApplyFilter,
        }),
        shallow
    );
    const { rolesMasterData, getAllRolesMasterApiFunc } = useMasterRolesApiStore(
        (state) => ({
            rolesMasterData: state.getAllRoles,
            getAllRolesMasterApiFunc: state.getAllRolesMasterApi,
        }),
        shallow
    );

    const headerQuickSelectedFilters = [
        { name, type: "name" },
        { createdStartDate: createdStartDate, type: "createdStartDate" },
        { createdEndDate, type: "createdEndDate" },
        { roleFilter, type: "roleFilter" },
        { statusFilter, type: "statusFilter" },
        { sort, type: "sort" },
    ];

    const [toggleFilter, setToggleFilter] = useState(false);

    const [gridData, setGridData] = useState({
        itemsDisplayed: 10,
        data: [],
    });
    const [loading, setLoading] = useState(false);
    const [openDialoag, toggle] = useToggle();
    const statusData = [
        { status: "Active", id: 1 },
        { status: "Inactive", id: 2 },
    ];
    const applyUserListFilters = () => {
        setIsUserFiltersAppliedFunc();
        // setUserFilterInput({ filtersApplied: true });
        setLastAppliedFilterFunc({ name, createdStartDate, createdEndDate, role: roleFilter, status: statusFilter, sort });
    };

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            applyUserListFilters();
        }
    };
    const { response, error, status, loadingResponse } = useAxios({
        method: "get",
        url: Urls.GetAllUsers,
        headers: JSON.stringify({ userId: 1 }),
        responseState: [],
    });
    const availableProducts = Array.isArray(response.responseData) ? response.responseData.slice() : [];
    const scrollHandler = async (event) => {
        const e = event.nativeEvent;
        if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
            if (gridData.itemsDisplayed <= availableProducts.length) {
                setLoading(true);
                await new Promise((resolve) => setTimeout(resolve, 1000));
                setGridData({
                    itemsDisplayed: gridData.itemsDisplayed + 10,
                    data: availableProducts.slice(0, gridData.itemsDisplayed + 10),
                });
                setLoading(false);
                // setGridData((oldData) => {
                //     return oldData.concat(moreData);
                // });
            }
        }
    };
    const role = [];

    useEffect(() => {
        setGridData((prev) => ({
            ...prev,
            data: availableProducts.slice(0, 10),
        }));
        if (status) {
            setLoading(false);
        }
    }, [status]);

    const sortedData = orderBy(gridData?.data, sort);
    useEffect(() => {
        getAllRolesMasterApiFunc("get", buildURL(Urls.MasterApiGetAllRoles));
    }, []);

    return (
        <>
            <div>
                <PageTitle title={"Aviation"} subTitle={"All Operators"}>
                    <div className="col-6 text-end">
                        <div class="right-btn-group">
                            <button class="k-button height-38 k-button-md k-button-solid k-button-solid-primary k-rounded-md">
                                {/* <img src={{}} alt="Add Operator Icon" /> */}
                                <span class="k-button-text" onClick={toggle}>
                                    + Add Operator
                                </span>
                            </button>
                        </div>
                    </div>
                </PageTitle>

                <div className="data-list-view-wrapper">
                    <div className="data-grid-filter-sec">
                        <div className="filtered-btn-data">
                            <div className="filter-left-sec">
                                <Button fillMode="flat" className="filters-btn" onClick={() => setToggleFilter(!toggleFilter)}>
                                    Filters{" "}
                                    <img
                                        style={{
                                            transform: toggleFilter ? "rotate(0deg)" : "rotate(180deg)",
                                        }}
                                        src={FilterIcon}
                                    />
                                </Button>
                            </div>

                            <QuickRecentFiltersOperator headerQuickSelectedFilters={headerQuickSelectedFilters} toggleFilter={toggleFilter} />
                            <div className="filter-right-btn-sec">
                                <>
                                    <Button fillMode="outline" className="btn-outline-primary relative-icon-plus height-32" onClick={() => setResetUserGridFiltersFunc()}>
                                        Reset
                                    </Button>
                                    <Button
                                        fillMode="flat"
                                        className="text-primary height-32"
                                        onClick={() => {
                                            setToggleFilter(false);
                                            // setUserFilterInput(lastAppliedFilter);
                                            setOnClickApplyFilterFunc();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button fillMode="outline" className="btn-primary height-32" onClick={() => applyUserListFilters()}>
                                        Apply
                                    </Button>
                                </>
                            </div>
                        </div>

                        <div className="phoenix-form mt-20" style={{ display: toggleFilter ? "block" : "none" }}>
                            <div className="row" style={{ display: toggleFilter ? "flex" : "none" }}>
                                <div className="col-lg-4 col-sm-6 col-xl-2">
                                    <div className="form-group">
                                        <Input
                                            type="text"
                                            name="name"
                                            onChange={(e) => setUserFiltersFunc(e.target.name, e.target.value)}
                                            label="Search By Name"
                                            className="custom-input"
                                            value={name}
                                            onKeyDown={onKeyDownHandler}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-xl-2">
                                    <div className="form-group">
                                        <CustomSingleSelect
                                            dataList={statusData || []}
                                            value={statusFilter}
                                            setValue={setUserFiltersFunc}
                                            handleFilterName={(e) => setUserFiltersFunc("status", e.target.value)}
                                            keyName="status"
                                            textField="status"
                                            name="status"
                                            dataItemKey="id"
                                            label="Search By Status"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-xl-2">
                                    <div className="form-group">
                                        <CustomMultiSelect
                                            dataList={rolesMasterData?.data?.responseData || []}
                                            value={roleFilter}
                                            setValue={setUserFiltersFunc}
                                            handleFilterName={(e) => setUserFiltersFunc("role", e)}
                                            keyName="roleName"
                                            name="roleName"
                                            dataItemKey="aclRoleId"
                                            label="Search By Role"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-8 col-xl-4">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <DatePicker
                                                    formatPlaceholder={{
                                                        year: "yyyy",
                                                        day: "dd",
                                                        month: "mm",
                                                    }}
                                                    // formatPlaceholder="formatPattern"
                                                    label="Created Start"
                                                    name="createdStartDate"
                                                    className="custom-input datePicker"
                                                    onChange={(e) => setUserFiltersFunc(e.target.name, new Date(e.target.value))}
                                                    // max={dateRange.toDate ? dateRange.toDate : new Date()}
                                                    // defaultValue={createdStartDate}
                                                    // value={createdStartDate}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <DatePicker
                                                    formatPlaceholder={{
                                                        year: "yyyy",
                                                        day: "dd",
                                                        month: "mm",
                                                    }}
                                                    label="Created End"
                                                    name="createdEndDate"
                                                    onChange={(e) => setUserFiltersFunc(e.target.name, new Date(e.target.value))}
                                                    // min={dateRange.fromDate ? new Date(dateRange.fromDate) : undefined}
                                                    // defaultValue={dateRange.toDate}
                                                    className="custom-input datePicker"
                                                    // value={createdEndDate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Grid
                        style={{
                            height: "400px",
                        }}
                        className="all-user-data-table detail-rows-tbl last-child-center"
                        data={sortedData}
                        onScroll={scrollHandler}
                        fixedScroll={true}
                        sortable={true}
                        sort={sort}
                        onSortChange={(e) => setUserGridSortFunc(e)}
                    >
                        <GridNoRecords>{loadingResponse || loading ? <TableLoader /> : "No records available"}</GridNoRecords>
                        <Column
                            width="175px"
                            title="Operator Name"
                            field="name"
                            className="link-col"
                            cell={(item) => {
                                const {
                                    dataItem: { name },
                                } = item;
                                return <td className="link-col">{name}</td>;
                            }}
                        />
                        <Column width="200px" field="name" title="Grade" />
                        <Column width="200px" field="email" title="Email ID" />
                        <Column width="200px" field="name" title="Phone #" />
                        <Column width="200px" field="name" title="Base" />
                        <Column width="200px" field="name" title="Address" />
                        <Column width="200px" field="name" title="Fleet" />
                        <Column width="200px" field="name" title="Ratings" />
                        <Column width="200px" field="name" title="Status" />

                        <Column
                            width="50px"
                            filterable={false}
                            sortable={false}
                            title="Actions"
                            cell={(item) => (
                                <td className="text-center">
                                    <div className="grid-actions">
                                        <CustomTooltip placement={"top"} description={"Associated Trips"}>
                                            <Link>
                                                <Button className="edit-icon-btn" fillMode="flat" />
                                            </Link>
                                        </CustomTooltip>
                                        <CustomTooltip placement={"top"} description={"Edit"}>
                                            <Link>
                                                <Button className="edit-icon-btn" fillMode="flat" />
                                            </Link>
                                        </CustomTooltip>
                                    </div>
                                </td>
                            )}
                        />
                    </Grid>
                    {openDialoag && <CreateOperatorWindow openDialog={openDialoag} toggle={toggle} />}
                    {loading && <TableLoader />}
                    <br />
                    {/* showing: {gridData.data?.length} items */}
                </div>
            </div>
        </>
    );
};

export default OpearatorsList;
