import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useState } from "react";

export const CustomSuggestiveSingleSelect = ({ dataList, handleFilterName, value, label, dataItemKey, keyName, textField, classN, onCloseEnv = {}, loading = false, filterable=true, isFromLingLeg=false }) => {
    // const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = React.useState(null);

    const handleChange = (event) => {
        if(event?.target?.value?.isDisable){
            return
        }
        handleFilterName(event);
    };

    const handleFilterChange = (event) => {
        setFilter(event.filter);
        // setFilteredData(filteredData);
    };

    // useEffect(() => {
    //     setFilteredData(dataList);
    // }, [dataList]);
    const onClose = (e) => {
        if (e.nativeEvent.code === "Enter") {
            handleFilterName(e);
        }
        onCloseEnv(e);
        setFilter({ value: "" });
    };

    const filteredData = !filter ? dataList : dataList?.filter((item) => item[textField]?.toLowerCase()?.includes(filter?.value?.toLowerCase()));

    return (
        <div>
            <DropDownList
                data={filteredData}
                value={value}
                onChange={handleChange}
                onFilterChange={handleFilterChange}
                label={label}
                className={"custom-input " + classN}
                dataItemKey={dataItemKey}
                textField={textField}
                filterable={filterable}
                filterPlaceholder="Search..."
                filter={filter?.value}
                onClose={onClose}
                itemRender={(li, item) => itemRender(li, item, isFromLingLeg)}
                loading={loading}
            />
        </div>
    );
};

const itemRender = (li, itemProps, isFromLingLeg) => {
    let itemChildren = null;
    if(isFromLingLeg){
        itemChildren = (
            <span className="custom-dropdown-item" style={{ color: itemProps?.dataItem?.isDisable ? "#808080" : "#00106e",}}>
                {itemProps?.dataItem?.ListName}
            </span>
        );
    }else{
        itemChildren = (
            <span style={{ color: "#00106e" }}>
                {li?.props?.children}
            </span>
        );
    }
    return React.cloneElement(li, li.props, itemChildren);
};
