import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import adminIconBlue from "assets/images/admin-icon-blue.svg";
import arrowLeftRightBlue from "assets/images/arrow-left-right-blue.svg";
import arrowLeftRightDblBlue from "assets/images/arrow-left-right-dbl-blue.svg";
import changeShedulerBlue from "assets/images/change-sheduler-blue.svg";
import clockIconBlue from "assets/images/clock-icon-blue.svg";
import edit from "assets/images/edit.svg";
import graphIconBlue from "assets/images/graph-icon-blue.svg";
import planeIconBlue from "assets/images/plan-icon-blue.svg";
import splitIconBlue from "assets/images/split-icon-blue.svg";
import combineIconBlue from "assets/images/combine-icon-blue.svg";
import warningOrange from "assets/images/warning-orange.svg";
import pencilBlue from "assets/images/pencil-blue.svg";
import deleteBlue from "assets/images/delete-blue.svg";
import "assets/css/Accordian.scss";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { useNavigate } from "react-router-dom";

const AircraftsList = () => {
    const navigate = useNavigate();

    const navigateToPreviousPage = () => {
        navigate("/leg-list");
    };
    return (
        <div className="mt-20">
            <div className="container-fluid p-0">
                <div className="custom-form-card trip-form mb-20">
                    <div className="row">
                        <div className="col-6">
                            <h3 className="form-title mb-30">Select & Confirm Legs to Schedule</h3>
                        </div>
                        <div className="col-6 text-end">
                            <div className="trip-label">
                                <strong>Trip ID:</strong> Charter-0922-0012 <strong className="mx-1"> | </strong> <span className="main-status-icon"><span class="in-queue elips-status-icon me-0  top-0"></span></span> <strong> In Queue</strong>
                            </div>
                            <div className="client-name">
                                <strong>Client:</strong> Beyonce Knowles
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <strong className="text-dark-grey mb-1 fs-14 f-lh-20 font-600 ">Trip Overview</strong>
                    </div>
                    <div className="overflow-auto">
                        <div className="main-custom-accordian trip-accordian">
                            <PanelBar>
                                <PanelBarItem
                                    expanded={false}
                                    title={
                                        <div className="accordian-box">
                                            <div className="items">
                                                <label className="label">Full Route</label>
                                                <div className="details fs-14 text-dark-blue font-500">
                                                    JFK <img src={arrowLeftRightBlue} alt="Arrow Icon" /> PHX <img src={arrowLeftRightDblBlue} alt="Arrow Double Icon" /> DFW
                                                </div>
                                            </div>
                                            <div className="items">
                                                <label className="label">Trip Dates</label>
                                                <div className="details fs-14 text-dark-blue">Feb 10 - 13, 2023</div>
                                            </div>
                                            <div className="items">
                                                <label className="label">Aircraft</label>
                                                <div className="details fs-14 text-dark-blue">Light</div>
                                            </div>
                                            <div className="items">
                                                <label className="label">Verbal Quote</label>
                                                <div className="details fs-14 text-dark-blue">$34,000.00</div>
                                            </div>
                                            <div className="items">
                                                <label className="label">Margin</label>
                                                <div className="details fs-14 text-dark-blue">3.4%</div>
                                            </div>
                                            <div className="items">
                                                <label className="label">Assigned Broker</label>
                                                <div className="details fs-14 text-dark-blue">Manni Scarso</div>
                                            </div>
                                            <div className="items">
                                                <label className="label">Additional Notes</label>
                                                <div className="details fs-14 text-dark-blue">Additional notes section for notes entered during trip inquiry...</div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <div className="parent-custom-accordian-sub">
                                        <div className="custom-accordian-sub mb-10">
                                            <PanelBar>
                                                <PanelBarItem
                                                    expanded={false}
                                                    title={
                                                        <div className="k-content-box">
                                                            <div className="accordian-box sub-accordian-box">
                                                                <div className="items">
                                                                    <input type="text" className="k-checkbox" />
                                                                    <span className="text-dark-grey font-500">Leg 1 | JFK (New York, NY) - PHX (Phoenix, AZ)</span>
                                                                </div>
                                                                <div className="items">
                                                                    <div className="d-flex text-dark-grey font-500 fs-14">
                                                                        <div className="mr-20">
                                                                            <img src={planeIconBlue} alt="Plane Icon" className="mr-5" />
                                                                            Feb 10, 2023 at 10:20am
                                                                        </div>
                                                                        <div className="mr-20">
                                                                            <img src={clockIconBlue} alt="Clock Icon" className="mr-5" />
                                                                            ETE: 02:35
                                                                        </div>
                                                                        <div className="me-3">
                                                                            <img src={adminIconBlue} alt="Admin Icon" className="mr-5" />3
                                                                        </div>
                                                                        <div>
                                                                            <img src={graphIconBlue} alt="Graph Icon" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    {" "}
                                                    <div className="custom-accordian-sub-details">
                                                        <div className="box">
                                                            <div className="items">
                                                                <label className="label">Special Requests</label>
                                                                <div className="fs-14 text-dark-blue">Special Request 1 | Additional notes...</div>
                                                            </div>
                                                            <div className="items">
                                                                <label className="label">Additional Notes</label>
                                                                <div className="fs-14 text-dark-blue">Passenger carrying golf clubs 65lbs</div>
                                                            </div>
                                                            <div className="items">
                                                                <label className="label">Scheduler</label>
                                                                <div className="fs-14 text-dark-blue">Dan Sutterland</div>
                                                            </div>
                                                            <div className="items">
                                                                <label className="label">Status</label>
                                                                <div className="fs-14 text-dark-blue">
                                                                    <span class="orange-elips"></span> Pending Pricing
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </PanelBarItem>
                                            </PanelBar>
                                        </div>
                                        <div className="custom-accordian-sub disabled">
                                            <PanelBar>
                                                <PanelBarItem
                                                    expanded={false}
                                                    title={
                                                        <div className="k-content-box">
                                                            <div className="accordian-box sub-accordian-box">
                                                                <div className="items">
                                                                    <input type="text" className="k-checkbox" />
                                                                    <span className="text-dark-grey font-500">Leg 1 | JFK (New York, NY) - PHX (Phoenix, AZ)</span>
                                                                </div>
                                                                <div className="items">
                                                                    <img src={warningOrange} alt="Warning Icon" width="15" height="15" className="mr-5" />
                                                                    <span className="fs-12 f-lh-20 font-400 text-dark-grey">You are not an assigned scheduler to this leg.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    {" "}
                                                    <div className="custom-accordian-sub-details">
                                                        <div className="box">
                                                            <div className="items">
                                                                <label className="label">Special Requests</label>
                                                                <div className="fs-14 text-dark-blue">Special Request 1 | Additional notes...</div>
                                                            </div>
                                                            <div className="items">
                                                                <label className="label">Additional Notes</label>
                                                                <div className="fs-14 text-dark-blue">Passenger carrying golf clubs 65lbs</div>
                                                            </div>
                                                            <div className="items">
                                                                <label className="label">Scheduler</label>
                                                                <div className="fs-14 text-dark-blue">Dan Sutterland</div>
                                                            </div>
                                                            <div className="items">
                                                                <label className="label">Status</label>
                                                                <div className="fs-14 text-dark-blue">Pending Pricing</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </PanelBarItem>
                                            </PanelBar>
                                        </div>
                                    </div>
                                </PanelBarItem>
                            </PanelBar>
                        </div>
                    </div>

                    <div className="create-scenerio-box mt-20">
                        <div class="fs-12 text-dark-grey">CREATE NEW SCENARIO</div>
                        <div className="fs-14 font-700 primary-light-blue">
                            <img src={splitIconBlue} alt="Split Icon" /> Split Scenario
                            <img src={combineIconBlue} alt="Combined Icon" className="ml-30" /> Combined Scenario
                        </div>
                    </div>
                </div>

                <div className="custom-form-card build-split-scenario">
                    <div className="row">
                        <div className="col-9">
                            <div className=" mb-20 d-flex">
                                <input type="text" className="k-checkbox mr-10" />
                                <h3 className="build-split-scenario-title mb-0">
                                    Build Split Scenario for{" "}
                                    <span className="underline pb-1">
                                        Split Scenario Name <img src={pencilBlue} alt="Pencil Icon" width="16" height="16" />
                                    </span>
                                </h3>
                            </div>
                        </div>
                        <div className="col-3 text-end">
                            <img src={deleteBlue} alt="Delete Icon" width="17" height="18" />
                        </div>
                    </div>

                    <div className="overflow-auto">
                        <div className="main-custom-accordian">
                            <PanelBar>
                                <PanelBarItem
                                    expanded={false}
                                    title={
                                        <div className="accordian-box">
                                            <div className="items">
                                                <span className="text-dark-grey font-500">Leg 1 | JFK (New York, NY) - PHX (Phoenix, AZ)</span>
                                            </div>
                                            <div className="items">
                                                <div className="d-flex text-dark-grey font-500 fs-14">
                                                    <div className="mr-20">
                                                        <img src={planeIconBlue} alt="Plane Icon" className="mr-5" />
                                                        Feb 10, 2023 at 10:20am
                                                    </div>
                                                    <div className="mr-20">
                                                        <img src={clockIconBlue} alt="Clock Icon" className="mr-5" />
                                                        ETE: 02:35
                                                    </div>
                                                    <div>
                                                        <img src={adminIconBlue} alt="Admin Icon" className="mr-5" />3
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                ></PanelBarItem>
                            </PanelBar>
                        </div>
                    </div>

                    <Grid
                        style={{
                            height: "400px",
                        }}
                    >
                        <Column field="ProductID" title="ID" width="40px" />
                        <Column field="ProductName" title="Name" width="250px" />
                        <Column field="Category.CategoryName" title="CategoryName" />
                        <Column field="UnitPrice" title="Price" />
                        <Column field="UnitsInStock" title="In stock" />
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default AircraftsList;
