import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import axios from "axios";
import { persist } from "zustand/middleware";

const userStore = immer((set) => ({
    userData: {},
    getApi: async (method, url, code, config) => {
        let isLogin = true;
        const VIEWLEGS = "VIEWLEGS";
        const apiResponse = await axios[method](url, code, config);
        apiResponse?.data?.responseData?.userData?.permissions?.map((v) => {
            if (!v.includes(VIEWLEGS)) {
                isLogin = false;
            }
        });
        if (isLogin) {
            set({ userData: apiResponse });
            return apiResponse;
        }
        return apiResponse;
    },
    setUserData: (data) => {
        set((state) => ({
            userData: data,
        }));
    },
}));

const persistConfig = {
    name: "loggedInUser",
    onSet: (state) => state,
};

export const useUserStore = create(devtools(persist(userStore, persistConfig)));
