import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ModalPopup = ({ handleShow, show, handleClose, title, description, closeButtonTitle, actionButtonTitle, actionClassname = false, onClick, hideModalheader = false }) => {
    useEffect(() => {
        if (show) {
            document.body.classList?.add("hide-scroll-on-modal-open");
            return () => {
                document.body.classList?.remove("hide-scroll-on-modal-open");
            };
        }
    }, [show]);
    return (
        <>
            <Modal className={actionClassname ? "delete-modal" : "generic-modal"} show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <div className="custom-modal">
                    {!hideModalheader && (
                        <Modal.Header>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                    )}
                    <Modal.Body>{description}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" className="height-42" onClick={handleClose}>
                            {closeButtonTitle}
                        </Button>
                        <Button variant="primary" className="height-42" onClick={onClick}>
                            {actionButtonTitle}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
};

export default ModalPopup;
