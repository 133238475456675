import * as React from "react";
import { Popover as Popovers, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import { CustomMultiSelect } from "components/common/CustomMultiSelect";
import propMatchup from "assets/images/prop-matchup.svg";
import propAlert from "assets/images/prop-alert.svg";
import propCancellation from "assets/images/prop-cancellation.svg";
import propActive from "assets/images/prop-active.svg";
import propInactive from "assets/images/prop-inactive.svg";
import propQueue from "assets/images/prop-queue.svg";
import { shallow } from "zustand/shallow";
import { useMasterApiStore } from "store/MasterDataStore";
import { checkBrokerTypeWithSpace, filterDataByHighestPriority, formatBrokerTypes, formatRelativeTime, rednderLegStatusIcon } from "helpers";
import CustomTooltip from "components/common/CustomTooltip";
import { useSubStatusStore } from "store/SubStatusStore";
const SubStatus = (props) => {
    const { data, index, showRowSubStatus, setShowRowSubStatus, handleAssignSchedulerSubStatus } = props;
    const anchor = React.useRef(null);
    const multiselectRef = React.useRef()
    const [filteredObjects, setFilteredObjects] = React.useState([]);
    const { subStatusData, setSubStatusData } = useSubStatusStore()
      
    const filterData = (data, ids) => {
        return data?.filter((item) => ids?.includes(item.id?.toString()));
    };
    const [checkIfUsermadeAnyChanges, setCheckIfUsermadeAnyChanges] = React.useState(false);
    const { getAllSchedulerSubStatusMasterData } = useMasterApiStore(
        (state) => ({
            getAllSchedulerSubStatusMasterData: state.getAllSchedulerSubStatusMasterData,
        }),
        shallow
    );
    const getAllSchedulerSubStatusMasterDataRemovedNew = getAllSchedulerSubStatusMasterData?.data?.responseData?.filter((f) => f?.id !== 7);

    const onOpenPopUp = () => {
        setSubStatusData({ subStatus: filteredObjects, data })
        setShowRowSubStatus(index);
    };
    
    React.useEffect(() => {       
        if(getAllSchedulerSubStatusMasterData?.data?.responseData) {
            const InitialFilterData = filterData(getAllSchedulerSubStatusMasterData?.data?.responseData, data?.phoLegsSchedulerSubStatus)?.filter((f) => f?.id != "7")
            setFilteredObjects(InitialFilterData)
        }
    }, [getAllSchedulerSubStatusMasterData, data])

    const maxPriorityObject = React.useMemo(() => {
        const InitialFilterData = filterData(getAllSchedulerSubStatusMasterData?.data?.responseData, data?.phoLegsSchedulerSubStatus)?.filter((f) => f?.id != "7")
        const filteredPriorityDataBasedOnPriority = InitialFilterData?.map((f) => f.priority);
        const highestpriority = filterDataByHighestPriority(getAllSchedulerSubStatusMasterData?.data?.responseData, filteredPriorityDataBasedOnPriority?.toString());
        return highestpriority?.[0]
    }, [data?.phoLegsSchedulerSubStatus])
    
    const handleEvent = (event) => {
        const newItem = event?.items?.[0];
        const operation = event?.operation; 
        let tempFilteredObjects = Array.isArray(filteredObjects) ? [...filteredObjects] : [];
        if (operation === "toggle" && newItem) {
            const id = newItem.id;
            const itemIndex = tempFilteredObjects.findIndex((obj) => obj.id === id);
            if (itemIndex !== -1) {
                tempFilteredObjects.splice(itemIndex, 1);
            } else {
                const inactiveIndex = tempFilteredObjects.findIndex((obj) => obj.id === 2);
                switch (id) {
                    case 1:
                        if (inactiveIndex !== -1) {
                            tempFilteredObjects.splice(inactiveIndex, 1);
                        }
                        tempFilteredObjects.push(newItem);
                        break;
                    case 2:
                        tempFilteredObjects = [];
                        tempFilteredObjects.push(newItem);
                        break;
                    case 5:
                    case 3:
                        tempFilteredObjects.push(newItem);
                        if (!tempFilteredObjects.some((obj) => obj.id === 1)) {
                            tempFilteredObjects.push(getAllSchedulerSubStatusMasterDataRemovedNew.find((item) => item.id === 1));
                            if (inactiveIndex !== -1) {
                                tempFilteredObjects.splice(inactiveIndex, 1);
                            }
                        }
                        if (!tempFilteredObjects.some((obj) => obj.id === 6)) {
                            tempFilteredObjects.push(getAllSchedulerSubStatusMasterDataRemovedNew.find((item) => item.id === 6));
                        }
                        break;
                    case 4:
                    case 6:
                        tempFilteredObjects.push(getAllSchedulerSubStatusMasterDataRemovedNew.find((item) => item.id === id));
                        break;
                    default:
                        break;
                }
            }
        } else if (operation === "delete" && newItem) {
            tempFilteredObjects.splice(
                tempFilteredObjects.findIndex((obj) => obj.id === newItem.id),
                1
            );
        } else {
            tempFilteredObjects.length = [];
        }

        return tempFilteredObjects;
    };

    const handleSelectionChange  = (_, event) => {
        const newFilteredObjects = handleEvent(event)
        setFilteredObjects(newFilteredObjects)
        setSubStatusData({ subStatus: newFilteredObjects, data })
        multiselectRef?.current?.setValue([...newFilteredObjects])
    }

    const checkSubStatusChanged = () => {
        const updatedIds = subStatusData?.subStatus.map(item => item.id.toString())
        const oldIds = subStatusData?.data?.phoLegsSchedulerSubStatus !== "" ? subStatusData?.data?.phoLegsSchedulerSubStatus.split(",") : []
        if(updatedIds?.length !== oldIds?.length) return true;
        if(!updatedIds)  return false
        for(const id of updatedIds){
           if(!oldIds?.includes(id)) return true 
        }
        return false
    }
    React.useEffect(() => {
        const handleClickOutside = (event) => {
            const popoverInner = document.querySelector('.k-popover-inner');
            const childAnimationContainer = document.querySelector('.k-multiselecttree-popup')
            const layout = childAnimationContainer?.getBoundingClientRect();
            if (childAnimationContainer && layout.top < event.y && layout.bottom > event.y && layout.left < event.x && layout.right > event.x) {
                return;
            }
            if (popoverInner && !popoverInner.contains(event.target)) {
                setShowRowSubStatus(-1);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [subStatusData]);

    return (
        <div style={{position:"relative"}} >
            <div onClick={onOpenPopUp} className="d-flex cursor-pointer align-items-center main-status-box"    >
                <div className="main-status-icon">{rednderLegStatusIcon(data.schedulerStatus)}</div>
                <p className="mb-0 text" style={{padding:2}}>{data.schedulerStatus}</p>
                <CustomTooltip
                    placement={"top"}
                    hide={true}
                    description={
                        <span>
                            <p className="mb-0 text-dark-grey">
                                {" "}
                                <strong>{maxPriorityObject?.status} </strong>
                                <span className="text-light-grey">
                                    ({formatRelativeTime(data?.subStatusLastUpdated)}{" "}
                                    {data?.subStatusLastUpdatedBy && data?.subStatusLastUpdatedBy != "System"
                                        ? `by ${data?.subStatusLastUpdatedBy}`
                                        : formatBrokerTypes(checkBrokerTypeWithSpace(data.brokerName))}
                                    )
                                </span>
                            </p>{" "}
                        </span>
                    }
                >
                    <span className="sub-status cursor-pointer relative-icon-minus">
                        {maxPriorityObject?.status && maxPriorityObject?.status !== "New" && (
                            <img
                                src={
                                    maxPriorityObject.status === "Inactive"
                                        ? propInactive
                                        : maxPriorityObject.status === "Matchup"
                                        ? propMatchup
                                        : maxPriorityObject.status === "Mechanical Issue (MX)"
                                        ? propAlert
                                        : maxPriorityObject.status === "Cancellation (CX)"
                                        ? propCancellation
                                        : maxPriorityObject.status === "Active"
                                        ? propActive
                                        : maxPriorityObject.status === "Queue"
                                        ? propQueue
                                        : ""
                                }
                                alt=""
                                height="21"
                            />
                        )}
                    </span>
                </CustomTooltip>
            </div>
            <Button className="hideBtn"  ref={anchor}>  </Button>
            <Popovers className={"custom-popover custom-popovers"} show={showRowSubStatus === index} anchor={anchor.current && anchor.current.element} position={"top"} >
                <div className="popover-body  custom-status-popover">
                    <CustomMultiSelect
                    ref={multiselectRef}
                        setCheckIfUsermadeAnyChanges={setCheckIfUsermadeAnyChanges}
                        dataList={getAllSchedulerSubStatusMasterDataRemovedNew || []}
                        value={Array.isArray(filteredObjects) ? filteredObjects?.filter((f) => f?.status !== "New") : []}
                        handleFilterName={handleSelectionChange}
                        keyName="status"
                        name="schedulerSubStatus"
                        textField="status"
                        dataItemKey="id"
                        upDown={true}
                        label={
                            data.phoLegsSchedulerSubStatus ? (
                                <>
                                    <p className="fs-12 mb-0 text-light-grey"> Sub - Status</p>{" "}
                                    <p className="fs-16 mb-0 text-dark-grey d-inline-block">
                                        {maxPriorityObject?.id === "1"
                                            ? "Active"
                                            : maxPriorityObject?.id === "2"
                                            ? "Inactive"
                                            : maxPriorityObject?.id === "3"
                                            ? "Cancellation (CX)"
                                            : maxPriorityObject?.id === "4"
                                            ? "Matchup"
                                            : maxPriorityObject?.id === "5"
                                            ? "Mechanical Issue (MX)"
                                            : maxPriorityObject?.id === "6"
                                            ? "Queue"
                                            : ""}
                                    </p>
                                </>
                            ) : (
                                "Sub - Status"
                            )
                        }
                    />
                </div>
                <PopoverActionsBar>
                    <div className="model-footer-action text-end pt-1">
                        <Button
                            className="height-42 border-0 fs-14 font-700"
                            style={{ marginRight: "10px" }}
                            themeColor={"primary"}
                            fillMode="outline"
                            onClick={() => {
                                setShowRowSubStatus(-1);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button className="height-42 fs-14" themeColor={"primary"} onClick={() => {
                            handleAssignSchedulerSubStatus("schedulerSubStatus", filteredObjects, data?.legId, data)
                            setShowRowSubStatus(-1)
                        }}>
                            Save Changes
                        </Button>
                    </div>
                </PopoverActionsBar>
            </Popovers>
  
        </div>
    );
};
export default React.memo(SubStatus)
