import React, { useEffect, useState, useReducer, useRef, useCallback, useMemo, memo } from "react";
import { Popover as Popovers, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import { Button } from "@progress/kendo-react-buttons";
import CustomTooltip from "components/common/CustomTooltip";
import linkIcon from "assets/images/link-icon.svg";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import useAxiosPost from "hooks/useAxiosPost";
import { Urls, buildURL } from "helpers/api-urls";
import { CustomSuggestiveSingleSelect } from "components/common/CustomSuggestiveSingleSelect";
import { defaultSuccessMessage, formateDateLegList} from "helpers";
import deleteBlue from "assets/images/delete-blue.svg";
import { toast } from "react-toastify";
import "./linkLag.scss";
import { SuccessLinkLegessage } from "helpers/constants";
import LoaderInner from "../LoaderInner";
import ModalPopup from "components/common/Modal";
import { Dialog } from "@progress/kendo-react-dialogs";
import { DeleteNoteModal, DeletedSuccessMessage } from "helpers/constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTripProfileStore } from "store/TripProfileStore";
import { shallow } from "zustand/shallow";
const linkItem = {
    tripId: "",
    legId: "",
};
const LinkLeg = (props) => {
    const { data, index, showRowLinkLeg, setShowRowLinkLeg, setLinkLegCount, classLink } = props;
    const anchors = React.useRef(null);
    const [reRender, setReRender] = React.useState(true);
    const [lodingNumber, setLodingNumber] = React.useState(-1);
    const [lodingNumberLeg, setLodingNumberLeg] = React.useState({});
    const [isSave, setIsSave] = React.useState(false);
    const [hasUserMadeAnyChanges, setHasUserMadeAnyChanges] = useState(false);
    const [openedDropDown , setOpenedDropDown] = React.useState(false)

    const { openTripProfile, setOpenTripProfileView, setTripId, setDataSourceIdForTrip, setLegId  } = useTripProfileStore(
        (state) => ({
            setOpenTripProfileView: state.setOpenTripProfileView,
            setTripId: state.setTripId,
            setDataSourceIdForTrip: state.setDataSourceIdForTrip,
            setLegId: state.setLegId,
            openTripProfile: state.openTripProfile,
        }),
        shallow
    )
    const onOpenPopUp = () => {
        setShowRowLinkLeg(index);
    };
    const setLodingLeg = (flag, tripId) => {
        setLodingNumberLeg((prev) => {
            return { ...prev, [tripId]: flag };
        });
    };

    // React.useEffect(() => {
    // //  setReRender(!reRender);
    // }, [showRowLinkLeg]);
    const [legListFilterInput, setLegListFilterInput] = useState([]);
    const { loadingPostData, sendRequest, data: allLegList, status } = useAxiosPost();
    const [legList, setLegList] = useState({});
    const splitTripName = (str) => {
        return str?.split(" | ")[0];
    };
    const getLegList = async (value) => {
        value.map((id)=>{
            setLodingLeg(true, id);
        })
        await sendRequest("post", `${Urls.LegsById}`, value);
    };
    const [timeoutId, setTimeoutId] = useState(null);
    const { loadingPostData: tripIdDataLoading, sendRequest: sendRequestToGetTripIds, data: getTripIdsResponse } = useAxiosPost();
    const handleLegListGridFilter = (name, value, index) => {
        setHasUserMadeAnyChanges(true)
        setLegListFilterInput((prev) => {
            let prevData = prev.map((leg, ind) => {
                if (ind == index) {
                    leg = { ...leg, [name]: value };
                }
                return leg;
            });
            return prevData;
        });
    };
    const [tripList, setTripList] = useState([])
    const filterByTripId = useCallback(
        (name, value, list, index) => {
            setLodingNumber(index);
            const selected = list.props.data.find((item) => item === value) !== undefined;
            handleLegListGridFilter(name, value, index);
            setHasUserMadeAnyChanges(true)
            if (selected) {
                let ids = tripList.find((trip)=>trip.name == splitTripName(value));
                handleLegListGridFilter('tripIds', ids.id, index);
                // handleLegListGridFilter("id", "", index);
                getLegList([ids.id]);
            } else {
                handleLegListGridFilter("legId", "", index);
                handleLegListGridFilter("id", "", index);
                handleLegListGridFilter("tripIds", "", index);
                if (value) {
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                    }
                    const newTimeoutId = setTimeout(async () => {
                        setOpenedDropDown(false)
                        const payload = {
                            searchKey: value,
                        };
                        await sendRequestToGetTripIds("post", `${Urls.GetTripIds}`, {
                            ...payload,
                        });
                        setOpenedDropDown(true)
                    }, 500);
                    setTimeoutId(newTimeoutId);
                }
            }
        },
        [timeoutId, tripList]
    );
    const tripIdsData = useMemo(() => {
        let tripData = getTripIdsResponse?.responseData.map((trip)=>{
            if(data.tripId == trip.id){
                return {...trip, disabled: true};
            }
            return trip
        })
        setTripList(tripData);
        return Array.from(new Set(tripData?.map((d) => `${d?.name} | ${d?.clientname}`))) || [];
    }, [getTripIdsResponse?.responseData]);

    useEffect(() => {
        if (allLegList?.responseData?.length > 0) {
            let allKeys = allLegList?.responseData?.map((TId) => TId.tripId);
            const objForTripIds = {};
            allKeys.forEach((value) => {
                objForTripIds[value] = [];
            });
            Object.keys(objForTripIds).forEach((ids) => {
                objForTripIds[ids] = allLegList?.responseData
                    ?.filter((TId) => ids == TId.tripId)
                    .map((leg, index) => {
                        if(data.legId == leg.legId){
                            leg.isDisable = true
                        }
                        leg.ListName = `Leg ${leg.legNo} | ${leg.originAirportCode} - ${leg.destinationAirportCode} | ${formateDateLegList(leg?.departDate)}`;
                        return leg;
                    });
            });
            setLegList((prev) => {
                return {
                    ...prev,
                    ...objForTripIds,
                };
            });
            allKeys.map((ids) => {
                setLodingLeg(false, ids);
            });
            setLodingNumber(-1);
        }
    }, [allLegList]);

    const areAllValuesEmpty = (array) => {
        let val = array
            .map((leg) => {
                return {
                    legId: leg.legId,
                    tripId: leg.tripId,
                    tripIds: leg.tripIds,
                };
            })
            .every((obj) => Object.values(obj).every((value) => value !== ""));
        return legListFilterInput.length != 0 && val;
    };
    const { loadingPostData: saveLoading, sendRequest: saveLinkLegs, data: getLinkLegsResponse } = useAxiosPost();
    const saveLinkLeg = async () => {
        let LegLinkRequest = legListFilterInput.map((leg) => {
            return {
                legId: data?.legId,
                memberId: leg?.legId,
            };
        });
        setIsSave(true)

        let paylod =    LegLinkRequest = {
                "deleteAll": LegLinkRequest.length == 0 ? true : false,
                "legLinkReq": LegLinkRequest.length == 0 ?[
                  {
                    "legId": data?.legId,
                    "memberId": 0
                  }
                ]: LegLinkRequest
              };
        await saveLinkLegs("post", `${Urls.LegsLinked}`, paylod);
    };
    const { loadingPostData: getLoading, sendRequest: getLinkLegs, data: getLinkLegsListResponse } = useAxiosPost();
    useEffect(() => {
        if (showRowLinkLeg == index) {
            if(data?.legLinkProjection?.length > 0){
                let linkLeg = data?.legLinkProjection.map((leg, index) => {
                   
                    return {
                        tripId: `${leg.tripdisplayname} | ${leg.clientname}`,
                        legId: leg.memberid,
                        tripIds: leg.tripid,
                        id: leg.memberid,
                    };
                });
                getLegList(data?.legLinkProjection.map((trip)=>trip.tripid));
                setLegListFilterInput(linkLeg);
            }else{
                setLegListFilterInput([linkItem]);
            }
        }
    }, [data?.legId, showRowLinkLeg == index]);

    useEffect(() => {
        if (isSave && getLinkLegsResponse?.responseMessage == "Success" && getLinkLegsResponse?.methodName === "legsLink()") {
            toast.success(SuccessLinkLegessage(), defaultSuccessMessage);
            getLinkLegs("get", `${Urls.LegsLinked}/${data.legId}`);
        }
    }, [getLinkLegsResponse, showRowLinkLeg == index]);

    useEffect(() => {
        if (isSave &&  getLinkLegsListResponse?.responseMessage == "success" && getLinkLegsListResponse?.methodName == "listLinkedLegs()") {
            setLinkLegCount(getLinkLegsListResponse, data.legId);
            setIsSave(false);
            setShowRowLinkLeg(-1);
            setLegListFilterInput([]);
        }
    }, [getLinkLegsListResponse, showRowLinkLeg == index]);
    const [visibleConfirmationDialog, setVisibleConfirmationDialog] = useState(false);
    const toggleConfirmationDialog = () => {
        setVisibleConfirmationDialog(!visibleConfirmationDialog);
    };
    const handleCloseDeleteRoleModal = () => setShowDeleteRoleModal(false);
    const handleShowDeleteRoleModal = () => setShowDeleteRoleModal(true);
    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
    const [removedFile, setRemovedFile] = useState({});
    const { loadingPostData:deleteLoading, sendRequest: deleteLegLink, data: deleteLegResponse } = useAxiosPost();
    const [isDeleted, setIsDeleted] = useState(false)
    const deleteScenarios = async (file) => {
        setShowDeleteRoleModal(false);
        const updatedArray = legListFilterInput.filter((item, idx) => idx !== file.index);
        setLegListFilterInput(updatedArray);
        setIsDeleted(true)
        if (file.id) {
            deleteLegLink("delete", `${Urls.LegsLinked}/delete/${data.legId}/${file.legId}`).then((res) => {
                toast.success(DeletedSuccessMessage("Leg Link"), defaultSuccessMessage);
            });
        } else {
            toast.success(DeletedSuccessMessage("Leg Link"), defaultSuccessMessage);
        }
        setRemovedFile({})
    };

    const closePopup=(isDeleted)=>{
        if (isDeleted) {
            setIsSave(true);
            getLinkLegs("get", `${Urls.LegsLinked}/${data.legId}`);
        } else {
            setShowRowLinkLeg(-1);
            setLegListFilterInput([]);
        }
    }
    const [showTooltip, setShowTooltip] = useState(false);
    const openTripOverviewPanel = (e, leg) => {
        e.stopPropagation();
        if(openTripProfile){
            setOpenTripProfileView(false);
            setLegId("");
        }
        setTimeout(() => {
            setShowTooltip(false);
            setOpenTripProfileView(true);
            setDataSourceIdForTrip(leg.datasourceid);
            setTripId(leg.tripid);
            setLegId(leg.datasourcelegid);
        }, openTripProfile ? 200 : 0);
        
    }
    return (
        <div style={{ position: "relative" }} key={index + "innerLink"}>
            <div  className="d-flex cursor-pointer align-items-center main-status-box">
                {data?.linkedLegsCount > 0 && (
                    <OverlayTrigger
                        show={showTooltip}
                        trigger={["hover", "focus"]}
                        key={"top"}
                        placement={"top"}
                        overlay={
                            <Tooltip className="hidetooltip" id={`tooltip-top`} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
                                <span>
                                    {data?.legLinkProjection?.map((leg) => {
                                        return (
                                            <p className="mb-0 text-start" role="button" onClick={(e) => openTripOverviewPanel(e, leg)} style={{zIndex: 9999}}>
                                                {leg.tripdisplayname} Leg {leg.pos} {leg.originairport} - {leg.destinationairport}
                                            </p>
                                        );
                                    })}
                                </span>
                            </Tooltip>
                        }
                    >
                        <div className={`div-count ${classLink}`} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} onClick={onOpenPopUp}>
                        <img src={linkIcon} width={20} className="rotate-90" />
                            <span className="link-col aligns-count">{data?.linkedLegsCount}</span>
                        </div>
                    </OverlayTrigger>
                )}
                <div onClick={onOpenPopUp} className={`div-count ${classLink}`}>{!data?.linkedLegsCount && data?.linkedLegsCount == 0 && <img src={linkIcon} width={20} className="rotate-90 count-0" />}</div>
            </div>
            {showRowLinkLeg == index && (
                <Dialog
                    autoFocus={true}
                    title={"Linked Legs"}
                    onClose={() => {
                        if (hasUserMadeAnyChanges) {
                            toggleConfirmationDialog();
                        } else {
                            closePopup(isDeleted);
                        }
                    }}
                    className="add-contact-details-model edit-contact-modal right-open-modal w-750px"
                >
                    <div className="popover-body1  custom-status-popover1 phoenix-form">
                        {legListFilterInput?.map((leg, index) => {
                            return (
                                <div className="row  mt-2" key={index}>
                                    <div className="col-11">
                                        <div className="row ">
                                            <div className="col-6 pr-0">
                                                <div className="form-group">
                                                    <AutoComplete
                                                        data={tripIdsData || [""]}
                                                        value={leg.tripId}
                                                        onChange={(e) => {
                                                            filterByTripId(e.target.name, e.target.value, e.target, index);
                                                            setOpenedDropDown(false);
                                                        }}
                                                        name="tripId"
                                                        label="Trip Id"
                                                        className="fontsize-12"
                                                        skipDisabledItems={false}
                                                        loading={tripIdDataLoading && lodingNumber == index}
                                                        opened={openedDropDown && lodingNumber == index}
                                                        onBlur={() => setOpenedDropDown(false)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6 pr-0">
                                                <div className="form-group">
                                                    <CustomSuggestiveSingleSelect
                                                        dataList={legList[leg.tripIds] || []}
                                                        value={leg.legId ? legList[leg.tripIds]?.find((legItem) => legItem.legId == leg.legId) : ""}
                                                        handleFilterName={(e) => handleLegListGridFilter("legId", e.target.value.legId, index)}
                                                        name="legId"
                                                        keyName="ListName"
                                                        textField="ListName"
                                                        filterable={false}
                                                        onCloseEnv={() => {}}
                                                        classN={"custom-input_new fontsize-12"}
                                                        dataItemKey="legId"
                                                        label="Leg "
                                                        loading={lodingNumberLeg[splitTripName(leg.tripId)]}
                                                        isFromLingLeg={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-1 pr-0 mt-2">
                                        <div className="col-12 pr-0">
                                            <span
                                                onClick={() => {
                                                    deleteScenarios({ ...leg, index });
                                                }}
                                                className="cursor-pointer ml-2"
                                            >
                                                <img src={deleteBlue} alt="Delete Icon" width="15" className="relative-icon-minus" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="row mt-2  text-end" key={index}>
                            <div className="col-12 text-end">
                                <a
                                    className="height-42 fs-14 link-col cursor"
                                    themeColor={"primary"}
                                    onClick={() => {
                                        setHasUserMadeAnyChanges(true);
                                        setLegListFilterInput((prevState) => [...prevState, linkItem]);
                                    }}
                                >
                                    <b>+ Link New Leg</b>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="model-footer-action">
                        <Button
                            className="height-42 border-0 fs-14 font-700"
                            themeColor={"primary"}
                            fillMode="outline"
                            onClick={() => {
                                if (hasUserMadeAnyChanges) {
                                    toggleConfirmationDialog();
                                } else {
                                    closePopup(isDeleted);
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        {!areAllValuesEmpty(legListFilterInput) && (
                            <CustomTooltip
                                placement={"top"}
                                hide={true}
                                description={
                                    <p className="mb-0 text-dark-grey text-left" style={{ color: "red" }}>
                                        Trip ID and Leg ID is required and must not be empty.
                                    </p>
                                }
                            >
                                <Button
                                    className="height-42 fs-14"
                                    themeColor={"primary"}
                                    disabled={!areAllValuesEmpty(legListFilterInput)}
                                    onClick={() => {
                                        saveLinkLeg();
                                    }}
                                >
                                    Update Link Leg
                                </Button>
                            </CustomTooltip>
                        )}

                        {areAllValuesEmpty(legListFilterInput) && (
                            <Button
                                className="height-42 fs-14"
                                themeColor={"primary"}
                                onClick={() => {
                                    saveLinkLeg();
                                }}
                            >
                                Update Link Leg
                            </Button>
                        )}
                        {/* <Button
                            className="height-42 fs-14"
                            themeColor={"primary"}
                            disabled={!areAllValuesEmpty(legListFilterInput)}
                            onClick={() => {
                                saveLinkLeg();
                            }}
                        >
                            Update Link Leg
                        </Button> */}
                    </div>
                    {(saveLoading || (getLoading && !isDeleted)) && <LoaderInner />}
                </Dialog>
            )}
            <ModalPopup
                handleShow={handleShowDeleteRoleModal}
                show={showDeleteRoleModal}
                handleClose={handleCloseDeleteRoleModal}
                description={DeleteNoteModal}
                title={"Delete Note"}
                actionClassname
                closeButtonTitle={"Close & Keep Note"}
                actionButtonTitle={"Delete Note"}
                onClick={deleteScenarios}
            />
            <ModalPopup
                handleShow={toggleConfirmationDialog}
                title={"Confirm Cancel"}
                onClick={() => {
                    toggleConfirmationDialog();
                    setShowRowLinkLeg(-1);
                    setLegListFilterInput([]);
                }}
                show={visibleConfirmationDialog}
                handleClose={toggleConfirmationDialog}
                description={"Do you want to Cancel? Any unsaved data will be Lost."}
                closeButtonTitle={"No"}
                actionButtonTitle={"Yes"}
            />

            {/* {showRowLinkLeg == index && (
                <Button className="hideBtn" ref={anchors} key={index + "id"}>
                    {" "}
                </Button>
            )} */}
            {/* <Popovers
                className={"custom-popover custom-popovers phoenix-form"}
                style={{ width: 600 }}
                show={showRowLinkLeg == index}
                anchor={anchors.current && anchors.current.element}
                position={"left"}
            >
                <p>
                    <b>Linked Legs</b>
                </p>
                <div className="popover-body1  custom-status-popover1 phoenix-form">
                    {legListFilterInput?.map((leg, index) => {
                        return (
                            <div className="row  mt-2" key={index}>
                                <div className="col-11">
                                    <div className="row ">
                                        <div className="col-6 pr-0">
                                            <div className="form-group">
                                                <AutoComplete
                                                    data={tripIdsData || [""]}
                                                    value={leg.tripId}
                                                    onChange={(e) => filterByTripId(e.target.name, e.target.value, e.target, index)}
                                                    name="tripId"
                                                    label="Trip Id"
                                                    className="fontsize-12"
                                                    loading={tripIdDataLoading && lodingNumber == index}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6 pr-0">
                                            <div className="form-group">
                                                <CustomSuggestiveSingleSelect
                                                    dataList={legList[(leg.tripIds)] || []}
                                                    value={legList[(leg.tripIds)]?.find((legItem) => legItem.legId == leg.legId)}
                                                    handleFilterName={(e) => handleLegListGridFilter("legId", e.target.value.legId, index)}
                                                    name="legId"
                                                    keyName="ListName"
                                                    textField="ListName"
                                                    onCloseEnv={() => {}}
                                                    classN={"custom-input_new fontsize-12"}
                                                    dataItemKey="legId"
                                                    label="Leg "
                                                    loading={lodingNumberLeg[splitTripName(leg.tripId)]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-1 pr-0 mt-2">
                                    <div className="col-12 pr-0">
                                        <span
                                            onClick={() => {
                                                const updatedArray = legListFilterInput.filter((item, idx) => idx !== index);
                                                setLegListFilterInput(updatedArray);
                                            }}
                                            className="cursor-pointer ml-2"
                                        >
                                            <img src={deleteBlue} alt="Delete Icon" width="15" className="relative-icon-minus" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="row mt-2  text-end" key={index}>
                        <div className="col-12 text-end">
                            <a
                                className="height-42 fs-14 link-col" 
                                themeColor={"primary"}
                                onClick={() => {
                                    setLegListFilterInput((prevState) => [...prevState, linkItem]);
                                }}
                            >
                                <b>+ Link New Leg</b>
                            </a>
                        </div>
                    </div>
                </div>
                <PopoverActionsBar>
                    <div className="model-footer-action text-end pt-1">
                        <Button
                            className="height-42 border-0 fs-14 font-700"
                            style={{ marginRight: "10px" }}
                            themeColor={"primary"}
                            fillMode="outline"
                            onClick={() => {
                                setShowRowLinkLeg(-1);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            className="height-42 fs-14"
                            themeColor={"primary"}
                            disabled={!areAllValuesEmpty(legListFilterInput)}
                            onClick={() => {
                                saveLinkLeg();
                            }}
                        >
                            Update Link Leg
                        </Button>
                    </div>
                </PopoverActionsBar>
            </Popovers> */}
        </div>
    );
};
export default React.memo(LinkLeg);
