import axios from "axios";
import { getMVToken, getToken } from "helpers/auth";
import { useState, useCallback } from "react";

const useAxiosPost = () => {
    const [ loadingPostData, setLoadingPostData ] = useState(false);
    const [ error, setError ] = useState(null);
    const [ data, setData ] = useState(null);
    const [ status, setStatus ] = useState("");

    const sendRequest = useCallback(async (method, url, requestData, source = "") => {
        setLoadingPostData(true);
        try {
            if (source == "MV") {
                axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL_MAVERICK;
                let tokenMV = getMVToken();
                if (tokenMV) {
                    axios.defaults.headers.common["Authorization"] = `Bearer ${tokenMV}`;
                    axios.defaults.headers.common["Content-Type"] = "application/json";
                }
            } else {
                if (getToken()) {
                    axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
                    axios.defaults.headers.common["Content-Type"] = "application/json";
                }
                axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
            }
            const response = await axios[method](url, requestData);

            setData(response.data);
            setStatus(response.status);
        } catch (err) {
            setError(err.response?.data);
        } finally {
            setLoadingPostData(false);
        }
    }, []);

    return {
        loadingPostData,
        error,
        data,
        sendRequest,
        status,
    };
};

export default useAxiosPost;
