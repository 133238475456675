import React from "react";
/** Default the color */
const themes = {
    dark: {
        backgroundColor: "black",
        color: "red",
    },
    light: {
        backgroundColor: "white",
        color: "black",
    },
};

const initialState = {
    dark: false,
    theme: themes.light,
    toggle: () => {},
    fontFamily: {
        REGULAR: "Regular",
        BOLD: "Bold",
        MEDIUM: "Medium",
        LIGHT: "Light",
    },
};
const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }) {
    const [dark, setDark] = React.useState(false);
    React.useEffect(() => {
        const isDark = localStorage.getItem("dark") === "true";
        setDark(isDark);
    }, [dark]);

    const toggle = () => {
        const isDark = !dark;
        localStorage.setItem("dark", JSON.stringify(isDark));
        setDark(isDark);
    };

    const theme = dark ? themes.dark : themes.light;

    return <ThemeContext.Provider value={{ theme, dark, toggle }}>{children}</ThemeContext.Provider>;
}

export { ThemeProvider, ThemeContext };
