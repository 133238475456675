import "./App.css";
import { ThemeProvider } from "theme/Theme";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { ViewportProvider } from "hooks/useViewPort";
import Navigation from "navigation/navigation";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { getMVADToken, getMVToken, getToken, setToken } from "helpers/auth";
import { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useClientProfileStore } from "store/ClientProfileStore";
import ClientProfile from "pages/ClientProfile";
import { useTripProfileStore } from "store/TripProfileStore";
import TripProfile from "pages/TripProfile";
import { useUserStore } from "store/Userdetails";
import { toast } from "react-toastify";
import { buildURL } from "helpers/api-urls";
import { customAPI, defaultSuccessMessage } from "helpers";
function App() {
    const VIEWLEGS = "VIEWLEGS";
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || "https://phoenixdev-be.apps.jets.com/phoenix/api/";
    const { authenticateAndGetUserDetailsApi, setUserData } = useUserStore(
        (state) => ({
            authenticateAndGetUserDetailsApi: state.getApi,
            setUserData: state.setUserData,
        }),
        shallow
    );
    useEffect(() => {
        if (getToken()) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
            axios.defaults.headers.common["Content-Type"] = "application/json";
        } else {
            const token = getMVToken();
            if (token) {
                (async () => {
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    };
                    let payload = { origin: window.location.origin };
                    const data = await customAPI("post", buildURL("authenticate-sso", "", "", "MV"), payload, config);
                    if (data?.responseCode == 200) {
                        let isLogin = true;
                        data?.responseData?.userData?.permissions?.map((v) => {
                            if (!v.includes(VIEWLEGS)) {
                                isLogin = false;
                            }
                        });
                        if (isLogin) {
                            setUserData({ data: data });
                            setToken(data?.responseData?.access_token);
                            toast.success("Logged In Successfully!", defaultSuccessMessage);
                            let rULR = window.location.search;
                            const query = rULR.split("?")[1];
                            const params = {};
                            if (query) {
                                const queryParams = query.split("&");
                                queryParams.forEach((param) => {
                                    const [key, value] = param.split("=");
                                    params[key] = value;
                                });
                            }
                            if (params && params.return_url) {
                                window.location.href = window.location.origin + decodeURIComponent(params.return_url);
                            } else {
                                window.location.href = window.location.origin + "/";
                            }
                        }
                    } else {
                        window.history.pushState("", "", "/login");
                    }
                })();
            }
        }
    }, []);

    const { openClientProfile, openData } = useClientProfileStore(
        (state) => ({
            openClientProfile: state.openClientProfile,
            openData: state.openData,
        }),
        shallow
    );
    const { openTripProfile } = useTripProfileStore(
        (state) => ({
            openTripProfile: state.openTripProfile,
        }),
        shallow
    );
    return (
        <ThemeProvider>
            <BrowserRouter>
                <ViewportProvider>
                    <Navigation></Navigation>
                    {openClientProfile && <ClientProfile openData={openData} />}
                    {openTripProfile && <TripProfile />}
                </ViewportProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
