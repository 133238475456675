import create from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import axios from "axios";
import { persist } from "zustand/middleware";

const masterApiStore = immer((set, get) => ({
    getAllTripTypesMasterData: [],
    getAllTripTypesMasterApi: async (method, url, code, config, cb = false) => {
        if (get().getAllTripTypesMasterData?.data?.responseData?.length && !cb) {
            set({ getAllTripTypesMasterData: get()?.getAllTripTypesMasterData });
        } else {
            const apiResponse = await axios[method](url, code, config);
            set({ getAllTripTypesMasterData: apiResponse });
            return apiResponse;
        }
    },
    getAllPriorityMasterData: [],
    getAllPriorityMasterApi: async (method, url, code, config, cb = false) => {
        if (get().getAllPriorityMasterData?.data?.responseData?.length && !cb) {
            set({ getAllPriorityMasterData: get()?.getAllPriorityMasterData });
        } else {
            const apiResponse = await axios[method](url, code, config);
            set({ getAllPriorityMasterData: apiResponse });
            return apiResponse;
        }
    },
    getAllBrokerMasterData: [],
    getAllBrokerMasterApi: async (method, url, code, config, cb = false) => {
        if (get().getAllBrokerMasterData?.data?.responseData?.length && !cb) {
            set({ getAllBrokerMasterData: get()?.getAllBrokerMasterData });
        } else {
            const apiResponse = await axios[method](url, code, config);
            set({ getAllBrokerMasterData: apiResponse });
            return apiResponse;
        }
    },
    getAllSchedulerMasterData: [],
    getAllSchedulerMasterApi: async (method, url, code, config, cb = false) => {
        if (get().getAllSchedulerMasterData?.data?.responseData?.length && !cb) {
            set({ getAllSchedulerMasterData: get()?.getAllSchedulerMasterData });
        } else {
            const apiResponse = await axios[method](url, code, config);
            set({ getAllSchedulerMasterData: apiResponse });
            return apiResponse;
        }
    },
    getAllAirportsMasterData: [],
    getAllAirportsMasterApi: async (method, url, code, config, cb = false) => {
        if (get().getAllAirportsMasterData?.data?.responseData?.length && !cb) {
            set({ getAllAirportsMasterData: get()?.getAllAirportsMasterData });
        } else {
            const apiResponse = await axios[method](url, code, config);
            set({ getAllAirportsMasterData: apiResponse });
            return apiResponse;
        }
    },
    getAllSchedulerStatusMasterData: [],
    getAllSchedulerStatusMasterApi: async (method, url, code, config, cb = false) => {
        if (get().getAllSchedulerStatusMasterData?.data?.responseData?.length && !cb) {
            set({ getAllSchedulerStatusMasterData: get()?.getAllSchedulerStatusMasterData });
        } else {
            const apiResponse = await axios[method](url, code, config);
            set({ getAllSchedulerStatusMasterData: apiResponse });
            return apiResponse;
        }
    },
    getAllSchedulerSubStatusMasterData: [],
    getAllSchedulerSubStatusMasterApi: async (method, url, code, config, cb = false) => {
        if (get().getAllSchedulerSubStatusMasterData?.data?.responseData?.length && !cb) {
            set({ getAllSchedulerSubStatusMasterData: get()?.getAllSchedulerSubStatusMasterData });
        } else {
            const apiResponse = await axios[method](url, code, config);
            set({ getAllSchedulerSubStatusMasterData: apiResponse });
            return apiResponse;
        }
    },
    getAllScenarioOpertorActionStatusMasterData: [],
    getAllScenarioOpertorActionStatusMasterApi: async (method, url, code, config, cb = false) => {
        if (get().getAllScenarioOpertorActionStatusMasterData?.data?.responseData?.length && !cb) {
            set({ getAllScenarioOpertorActionStatusMasterData: get()?.getAllScenarioOpertorActionStatusMasterData });
        } else {
            const apiResponse = await axios[method](url, code, config);
            set({ getAllScenarioOpertorActionStatusMasterData: apiResponse });
            return apiResponse;
        }
    },
}));

const persistConfig = {
    name: "getAllMasters",
    onSet: (state) => state,
};

export const useMasterApiStore = create(devtools(persist(masterApiStore, persistConfig)));
